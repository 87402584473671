import { DatabaseMock } from "./db_mock";  //TODO: Strip/comment out this interface
import { DatabaseFirebase } from "./db_firebase";
import { InterfaceDeviceBase, InterfaceDeviceSensor, InterfaceDeviceStatic, InterfaceDeviceType, InterfaceHub, InterfaceReading, InterfaceReadingThresholds, InterfaceReadingWarnings, InterfaceReadingWarningsSummary, InterfaceUserProfile } from "./interface_database";
import { CommonLib, CosecGeoPoint } from "./common";

//@ts-ignore
Array.prototype.random = function () {
    return this[Math.floor((Math.random()*this.length))];
}

export enum DATASET_ID {
    TEMPERATURE,
    HUMIDITY,
    PRESSURE,
    VBATT,
    VSOLAR,
    ANGLE,
    DISTRAW,
    DISTADJ
};

export enum SEVERITY {
    NONE = 0,
    INFO,
    WARN,
    CRIT
}

export interface LifetimeWarningData {
    stamp:Date;
    days:number;
    severity:SEVERITY;
}

export enum InterfaceHubGeoFeatureClasses {
    LV = "LV",
    HV_11KV = "HV_11KV",
    HV_33KV = "HV_33KV",
    HV_110KV = "HV_110KV",
    HV_132KV = "HV_132KV",
    HV_220KV = "HV_220KV",
    HV_400KV = "HV_400KV",
}

export const InterfaceHubGeoFeatureClassColors = new Map([
    [InterfaceHubGeoFeatureClasses.LV, "#644536"],
    [InterfaceHubGeoFeatureClasses.HV_11KV, "#7776BC"],
    [InterfaceHubGeoFeatureClasses.HV_33KV, "#CDC7E5"],
    [InterfaceHubGeoFeatureClasses.HV_110KV, "#FFFBDB"],
    [InterfaceHubGeoFeatureClasses.HV_132KV, "#FFEC51"],
    [InterfaceHubGeoFeatureClasses.HV_220KV, "#F0C808"],
    [InterfaceHubGeoFeatureClasses.HV_400KV, "#FF674D"],
]);

export enum InterfaceHubGeoFeatures {
    TRANSPOWER_TRANSMISSION_LINES = "TRANSPOWER_TRANSMISSION_LINES",
    TRANSPOWER_COOK_STRAIT = "TRANSPOWER_COOK_STRAIT",
    EQ_HV_CAPRICORNIA = "EQ_HV_CAPRICORNIA",
    EQ_HV_EE_GENERATION = "EQ_HV_EE_GENERATION",
    EQ_HV_ERGON_ENERGY = "EQ_HV_ERGON_ENERGY",
    EQ_HV_FAR_NORTH = "EQ_HV_FAR_NORTH",
    EQ_HV_MACKAY = "EQ_HV_MACKAY",
    EQ_HV_NORTH_QUEENSLAND = "EQ_HV_NORTH_QUEENSLAND",
    EQ_HV_SOUTH_WEST = "EQ_HV_SOUTH_WEST",
    EQ_HV_WIDE_BAY = "EQ_HV_WIDE_BAY",
    ENERGEX_132KV = "ENERGEX_132KV",
    ENERGEX_110KV = "ENERGEX_110KV",
    ENERGEX_33KV_GROUP1 = "ENERGEX_33KV_GROUP0",
    ENERGEX_33KV_GROUP2 = "ENERGEX_33KV_GROUP1",
    ENERGEX_33KV_GROUP3 = "ENERGEX_33KV_GROUP2",
    ENERGEX_33KV_GROUP4 = "ENERGEX_33KV_GROUP3",
    ENERGEX_33KV_GROUP5 = "ENERGEX_33KV_GROUP4",
    ENERGEX_11KV_CENTRAL_WEST_GROUP1 = "ENERGEX_11KV_CENTRAL_WEST_GROUP1",
    ENERGEX_11KV_CENTRAL_WEST_GROUP2 = "ENERGEX_11KV_CENTRAL_WEST_GROUP2",
    ENERGEX_11KV_CENTRAL_WEST_GROUP3 = "ENERGEX_11KV_CENTRAL_WEST_GROUP3",
    ENERGEX_11KV_CENTRAL_WEST_GROUP4 = "ENERGEX_11KV_CENTRAL_WEST_GROUP4",
    ENERGEX_11KV_METRO_NORTH_GROUP1 = "ENERGEX_11KV_METRO_NORTH_GROUP1",
    ENERGEX_11KV_METRO_NORTH_GROUP2 = "ENERGEX_11KV_METRO_NORTH_GROUP2",
    ENERGEX_11KV_METRO_NORTH_GROUP3 = "ENERGEX_11KV_METRO_NORTH_GROUP3",
    ENERGEX_11KV_METRO_NORTH_GROUP4 = "ENERGEX_11KV_METRO_NORTH_GROUP4",
    ENERGEX_11KV_METRO_NORTH_GROUP5 = "ENERGEX_11KV_METRO_NORTH_GROUP5",
    ENERGEX_11KV_METRO_NORTH_GROUP6 = "ENERGEX_11KV_METRO_NORTH_GROUP6",
    ENERGEX_11KV_METRO_NORTH_GROUP7 = "ENERGEX_11KV_METRO_NORTH_GROUP7",
    ENERGEX_11KV_METRO_NORTH_GROUP8 = "ENERGEX_11KV_METRO_NORTH_GROUP8",
    ENERGEX_11KV_METRO_NORTH_GROUP9 = "ENERGEX_11KV_METRO_NORTH_GROUP9",
    ENERGEX_11KV_METRO_NORTH_GROUP10 = "ENERGEX_11KV_METRO_NORTH_GROUP10",
    ENERGEX_11KV_METRO_NORTH_GROUP11 = "ENERGEX_11KV_METRO_NORTH_GROUP11",
    ENERGEX_11KV_METRO_NORTH_GROUP12 = "ENERGEX_11KV_METRO_NORTH_GROUP12",
    ENERGEX_11KV_METRO_NORTH_GROUP13 = "ENERGEX_11KV_METRO_NORTH_GROUP13",
    ENERGEX_11KV_METRO_NORTH_GROUP14 = "ENERGEX_11KV_METRO_NORTH_GROUP14",
    ENERGEX_11KV_METRO_NORTH_GROUP15 = "ENERGEX_11KV_METRO_NORTH_GROUP15",
    ENERGEX_11KV_METRO_SOUTH_GROUP1 = "ENERGEX_11KV_METRO_SOUTH_GROUP1",
    ENERGEX_11KV_METRO_SOUTH_GROUP2 = "ENERGEX_11KV_METRO_SOUTH_GROUP2",
    ENERGEX_11KV_METRO_SOUTH_GROUP3 = "ENERGEX_11KV_METRO_SOUTH_GROUP3",
    ENERGEX_11KV_METRO_SOUTH_GROUP4 = "ENERGEX_11KV_METRO_SOUTH_GROUP4",
    ENERGEX_11KV_METRO_SOUTH_GROUP5 = "ENERGEX_11KV_METRO_SOUTH_GROUP5",
    ENERGEX_11KV_METRO_SOUTH_GROUP6 = "ENERGEX_11KV_METRO_SOUTH_GROUP6",
    ENERGEX_11KV_METRO_SOUTH_GROUP7 = "ENERGEX_11KV_METRO_SOUTH_GROUP7",
    ENERGEX_11KV_METRO_SOUTH_GROUP8 = "ENERGEX_11KV_METRO_SOUTH_GROUP8",
    ENERGEX_11KV_METRO_SOUTH_GROUP9 = "ENERGEX_11KV_METRO_SOUTH_GROUP9",
    ENERGEX_11KV_METRO_SOUTH_GROUP10 = "ENERGEX_11KV_METRO_SOUTH_GROUP10",
    ENERGEX_11KV_METRO_SOUTH_GROUP11 = "ENERGEX_11KV_METRO_SOUTH_GROUP11",
    ENERGEX_11KV_METRO_SOUTH_GROUP12 = "ENERGEX_11KV_METRO_SOUTH_GROUP12",
    ENERGEX_11KV_METRO_SOUTH_GROUP13 = "ENERGEX_11KV_METRO_SOUTH_GROUP13",
    ENERGEX_11KV_METRO_SOUTH_GROUP14 = "ENERGEX_11KV_METRO_SOUTH_GROUP14",
    ENERGEX_11KV_METRO_SOUTH_GROUP15 = "ENERGEX_11KV_METRO_SOUTH_GROUP15",
    ENERGEX_11KV_METRO_SOUTH_GROUP16 = "ENERGEX_11KV_METRO_SOUTH_GROUP16",
    ENERGEX_11KV_NORTH_COAST_GROUP1 = "ENERGEX_11KV_NORTH_COAST_GROUP1",
    ENERGEX_11KV_NORTH_COAST_GROUP2 = "ENERGEX_11KV_NORTH_COAST_GROUP2",
    ENERGEX_11KV_NORTH_COAST_GROUP3 = "ENERGEX_11KV_NORTH_COAST_GROUP3",
    ENERGEX_11KV_NORTH_COAST_GROUP4 = "ENERGEX_11KV_NORTH_COAST_GROUP4",
    ENERGEX_11KV_NORTH_COAST_GROUP5 = "ENERGEX_11KV_NORTH_COAST_GROUP5",
    ENERGEX_11KV_NORTH_COAST_GROUP6 = "ENERGEX_11KV_NORTH_COAST_GROUP6",
    ENERGEX_11KV_NORTH_COAST_GROUP7 = "ENERGEX_11KV_NORTH_COAST_GROUP7",
    ENERGEX_11KV_NORTH_COAST_GROUP8 = "ENERGEX_11KV_NORTH_COAST_GROUP8",
    ENERGEX_11KV_NORTH_COAST_GROUP9 = "ENERGEX_11KV_NORTH_COAST_GROUP9",
    ENERGEX_11KV_NORTH_COAST_GROUP10 = "ENERGEX_11KV_NORTH_COAST_GROUP10",
    ENERGEX_11KV_NORTH_COAST_GROUP11 = "ENERGEX_11KV_NORTH_COAST_GROUP11",
    ENERGEX_11KV_NORTH_COAST_GROUP12 = "ENERGEX_11KV_NORTH_COAST_GROUP12",
    ENERGEX_11KV_NORTH_COAST_GROUP13 = "ENERGEX_11KV_NORTH_COAST_GROUP13",
    ENERGEX_11KV_NORTH_COAST_GROUP14 = "ENERGEX_11KV_NORTH_COAST_GROUP14",
    ENERGEX_11KV_SOUTH_COAST_GROUP1 = "ENERGEX_11KV_SOUTH_COAST_GROUP1",
    ENERGEX_11KV_SOUTH_COAST_GROUP2 = "ENERGEX_11KV_SOUTH_COAST_GROUP2",
    ENERGEX_11KV_SOUTH_COAST_GROUP3 = "ENERGEX_11KV_SOUTH_COAST_GROUP3",
    ENERGEX_11KV_SOUTH_COAST_GROUP4 = "ENERGEX_11KV_SOUTH_COAST_GROUP4",
    ENERGEX_11KV_SOUTH_COAST_GROUP5 = "ENERGEX_11KV_SOUTH_COAST_GROUP5",
    ENERGEX_11KV_SOUTH_COAST_GROUP6 = "ENERGEX_11KV_SOUTH_COAST_GROUP6",
    ENERGEX_11KV_SOUTH_COAST_GROUP7 = "ENERGEX_11KV_SOUTH_COAST_GROUP7",
    ENERGEX_11KV_SOUTH_COAST_GROUP8 = "ENERGEX_11KV_SOUTH_COAST_GROUP8",
    ENERGEX_11KV_SOUTH_COAST_GROUP9 = "ENERGEX_11KV_SOUTH_COAST_GROUP9",
    ENERGEX_11KV_SOUTH_COAST_GROUP10 = "ENERGEX_11KV_SOUTH_COAST_GROUP10",
    ENERGEX_11KV_WESTERN_GROUP1 = "ENERGEX_11KV_SOUTH_COAST_GROUP1",
    ENERGEX_11KV_WESTERN_GROUP2 = "ENERGEX_11KV_SOUTH_COAST_GROUP2",
    ENERGEX_11KV_WESTERN_GROUP3 = "ENERGEX_11KV_SOUTH_COAST_GROUP3",
    ENERGEX_11KV_WESTERN_GROUP4 = "ENERGEX_11KV_SOUTH_COAST_GROUP4",
    ENERGEX_11KV_WESTERN_GROUP5 = "ENERGEX_11KV_SOUTH_COAST_GROUP5",
    ENERGEX_11KV_WESTERN_GROUP6 = "ENERGEX_11KV_SOUTH_COAST_GROUP6",
    ENERGEX_11KV_WESTERN_GROUP7 = "ENERGEX_11KV_SOUTH_COAST_GROUP7",
    ENERGEX_11KV_WESTERN_GROUP8 = "ENERGEX_11KV_SOUTH_COAST_GROUP8",
    ENERGEX_11KV_WESTERN_GROUP9 = "ENERGEX_11KV_SOUTH_COAST_GROUP9",
    ENERGEX_11KV_WESTERN_GROUP10 = "ENERGEX_11KV_SOUTH_COAST_GROUP10",
    ENERGEX_11KV_WESTERN_GROUP11 = "ENERGEX_11KV_SOUTH_COAST_GROUP11",
    ENERGEX_11KV_WESTERN_GROUP12 = "ENERGEX_11KV_SOUTH_COAST_GROUP12",
    ENERGEX_11KV_WESTERN_GROUP13 = "ENERGEX_11KV_SOUTH_COAST_GROUP13",
    ENERGEX_11KV_WESTERN_GROUP14 = "ENERGEX_11KV_SOUTH_COAST_GROUP14",
    ENERGEX_11KV_WESTERN_GROUP15 = "ENERGEX_11KV_SOUTH_COAST_GROUP15",
    ENERGEX_11KV_WESTERN_GROUP16 = "ENERGEX_11KV_SOUTH_COAST_GROUP16",
    ENERGEX_LV_CENTRAL_WEST_GROUP1 = "ENERGEX_LV_CENTRAL_WEST_GROUP1",
    ENERGEX_LV_CENTRAL_WEST_GROUP2 = "ENERGEX_LV_CENTRAL_WEST_GROUP2",
    ENERGEX_LV_CENTRAL_WEST_GROUP3 = "ENERGEX_LV_CENTRAL_WEST_GROUP3",
    ENERGEX_LV_CENTRAL_WEST_GROUP4 = "ENERGEX_LV_CENTRAL_WEST_GROUP4",
    ENERGEX_LV_CENTRAL_WEST_GROUP5 = "ENERGEX_LV_CENTRAL_WEST_GROUP5",
    ENERGEX_LV_CENTRAL_WEST_GROUP6 = "ENERGEX_LV_CENTRAL_WEST_GROUP6",
    ENERGEX_LV_METRO_NORTH_GROUP1 = "ENERGEX_LV_METRO_NORTH_GROUP1",
    ENERGEX_LV_METRO_NORTH_GROUP2 = "ENERGEX_LV_METRO_NORTH_GROUP2",
    ENERGEX_LV_METRO_NORTH_GROUP3 = "ENERGEX_LV_METRO_NORTH_GROUP3",
    ENERGEX_LV_METRO_NORTH_GROUP4 = "ENERGEX_LV_METRO_NORTH_GROUP4",
    ENERGEX_LV_METRO_NORTH_GROUP5 = "ENERGEX_LV_METRO_NORTH_GROUP5",
    ENERGEX_LV_METRO_NORTH_GROUP6 = "ENERGEX_LV_METRO_NORTH_GROUP6",
    ENERGEX_LV_METRO_NORTH_GROUP7 = "ENERGEX_LV_METRO_NORTH_GROUP7",
    ENERGEX_LV_METRO_NORTH_GROUP8 = "ENERGEX_LV_METRO_NORTH_GROUP8",
    ENERGEX_LV_METRO_NORTH_GROUP9 = "ENERGEX_LV_METRO_NORTH_GROUP9",
    ENERGEX_LV_METRO_NORTH_GROUP10 = "ENERGEX_LV_METRO_NORTH_GROUP10",
    ENERGEX_LV_METRO_NORTH_GROUP11 = "ENERGEX_LV_METRO_NORTH_GROUP11",
    ENERGEX_LV_METRO_NORTH_GROUP12 = "ENERGEX_LV_METRO_NORTH_GROUP12",
    ENERGEX_LV_METRO_NORTH_GROUP13 = "ENERGEX_LV_METRO_NORTH_GROUP13",
    ENERGEX_LV_METRO_SOUTH_GROUP1 = "ENERGEX_LV_METRO_SOUTH_GROUP1",
    ENERGEX_LV_METRO_SOUTH_GROUP2 = "ENERGEX_LV_METRO_SOUTH_GROUP2",
    ENERGEX_LV_METRO_SOUTH_GROUP3 = "ENERGEX_LV_METRO_SOUTH_GROUP3",
    ENERGEX_LV_METRO_SOUTH_GROUP4 = "ENERGEX_LV_METRO_SOUTH_GROUP4",
    ENERGEX_LV_METRO_SOUTH_GROUP5 = "ENERGEX_LV_METRO_SOUTH_GROUP5",
    ENERGEX_LV_METRO_SOUTH_GROUP6 = "ENERGEX_LV_METRO_SOUTH_GROUP6",
    ENERGEX_LV_METRO_SOUTH_GROUP7 = "ENERGEX_LV_METRO_SOUTH_GROUP7",
    ENERGEX_LV_METRO_SOUTH_GROUP8 = "ENERGEX_LV_METRO_SOUTH_GROUP8",
    ENERGEX_LV_METRO_SOUTH_GROUP9 = "ENERGEX_LV_METRO_SOUTH_GROUP9",
    ENERGEX_LV_METRO_SOUTH_GROUP10 = "ENERGEX_LV_METRO_SOUTH_GROUP10",
    ENERGEX_LV_METRO_SOUTH_GROUP11 = "ENERGEX_LV_METRO_SOUTH_GROUP11",
    ENERGEX_LV_METRO_SOUTH_GROUP12 = "ENERGEX_LV_METRO_SOUTH_GROUP12",
    ENERGEX_LV_METRO_SOUTH_GROUP13 = "ENERGEX_LV_METRO_SOUTH_GROUP13",
    ENERGEX_LV_NORTH_COAST_GROUP1 = "ENERGEX_LV_NORTH_COAST_GROUP1",
    ENERGEX_LV_NORTH_COAST_GROUP2 = "ENERGEX_LV_NORTH_COAST_GROUP2",
    ENERGEX_LV_NORTH_COAST_GROUP3 = "ENERGEX_LV_NORTH_COAST_GROUP3",
    ENERGEX_LV_NORTH_COAST_GROUP4 = "ENERGEX_LV_NORTH_COAST_GROUP4",
    ENERGEX_LV_NORTH_COAST_GROUP5 = "ENERGEX_LV_NORTH_COAST_GROUP5",
    ENERGEX_LV_NORTH_COAST_GROUP6 = "ENERGEX_LV_NORTH_COAST_GROUP6",
    ENERGEX_LV_NORTH_COAST_GROUP7 = "ENERGEX_LV_NORTH_COAST_GROUP7",
    ENERGEX_LV_NORTH_COAST_GROUP8 = "ENERGEX_LV_NORTH_COAST_GROUP8",
    ENERGEX_LV_NORTH_COAST_GROUP9 = "ENERGEX_LV_NORTH_COAST_GROUP9",
    ENERGEX_LV_NORTH_COAST_GROUP10 = "ENERGEX_LV_NORTH_COAST_GROUP10",
    ENERGEX_LV_NORTH_COAST_GROUP11 = "ENERGEX_LV_NORTH_COAST_GROUP11",
    ENERGEX_LV_NORTH_COAST_GROUP12 = "ENERGEX_LV_NORTH_COAST_GROUP12",
    ENERGEX_LV_SOUTH_COAST_GROUP1 = "ENERGEX_LV_SOUTH_COAST_GROUP1",
    ENERGEX_LV_SOUTH_COAST_GROUP2 = "ENERGEX_LV_SOUTH_COAST_GROUP2",
    ENERGEX_LV_SOUTH_COAST_GROUP3 = "ENERGEX_LV_SOUTH_COAST_GROUP3",
    ENERGEX_LV_SOUTH_COAST_GROUP4 = "ENERGEX_LV_SOUTH_COAST_GROUP4",
    ENERGEX_LV_SOUTH_COAST_GROUP5 = "ENERGEX_LV_SOUTH_COAST_GROUP5",
    ENERGEX_LV_SOUTH_COAST_GROUP6 = "ENERGEX_LV_SOUTH_COAST_GROUP6",
    ENERGEX_LV_SOUTH_COAST_GROUP7 = "ENERGEX_LV_SOUTH_COAST_GROUP7",
    ENERGEX_LV_SOUTH_COAST_GROUP8 = "ENERGEX_LV_SOUTH_COAST_GROUP8",
    ENERGEX_LV_SOUTH_COAST_GROUP9 = "ENERGEX_LV_SOUTH_COAST_GROUP9",
    ENERGEX_LV_WESTERN_GROUP1 = "ENERGEX_LV_SOUTH_COAST_GROUP1",
    ENERGEX_LV_WESTERN_GROUP2 = "ENERGEX_LV_SOUTH_COAST_GROUP2",
    ENERGEX_LV_WESTERN_GROUP3 = "ENERGEX_LV_SOUTH_COAST_GROUP3",
    ENERGEX_LV_WESTERN_GROUP4 = "ENERGEX_LV_SOUTH_COAST_GROUP4",
    ENERGEX_LV_WESTERN_GROUP5 = "ENERGEX_LV_SOUTH_COAST_GROUP5",
    ENERGEX_LV_WESTERN_GROUP6 = "ENERGEX_LV_SOUTH_COAST_GROUP6",
    ENERGEX_LV_WESTERN_GROUP7 = "ENERGEX_LV_SOUTH_COAST_GROUP7",
    ENERGEX_LV_WESTERN_GROUP8 = "ENERGEX_LV_SOUTH_COAST_GROUP8",
    ENERGEX_LV_WESTERN_GROUP9 = "ENERGEX_LV_SOUTH_COAST_GROUP9",
    ENERGEX_LV_WESTERN_GROUP10 = "ENERGEX_LV_SOUTH_COAST_GROUP10",
}

export const InterfaceHubGeoFeatureClass = new Map([
    [InterfaceHubGeoFeatures.TRANSPOWER_TRANSMISSION_LINES, InterfaceHubGeoFeatureClasses.HV_220KV],
    [InterfaceHubGeoFeatures.TRANSPOWER_COOK_STRAIT, InterfaceHubGeoFeatureClasses.HV_400KV],
    [InterfaceHubGeoFeatures.EQ_HV_CAPRICORNIA, InterfaceHubGeoFeatureClasses.HV_220KV],
    [InterfaceHubGeoFeatures.EQ_HV_EE_GENERATION, InterfaceHubGeoFeatureClasses.HV_220KV],
    [InterfaceHubGeoFeatures.EQ_HV_ERGON_ENERGY, InterfaceHubGeoFeatureClasses.HV_220KV],
    [InterfaceHubGeoFeatures.EQ_HV_FAR_NORTH, InterfaceHubGeoFeatureClasses.HV_220KV],
    [InterfaceHubGeoFeatures.EQ_HV_MACKAY, InterfaceHubGeoFeatureClasses.HV_220KV],
    [InterfaceHubGeoFeatures.EQ_HV_NORTH_QUEENSLAND, InterfaceHubGeoFeatureClasses.HV_220KV],
    [InterfaceHubGeoFeatures.EQ_HV_SOUTH_WEST, InterfaceHubGeoFeatureClasses.HV_220KV],
    [InterfaceHubGeoFeatures.EQ_HV_WIDE_BAY, InterfaceHubGeoFeatureClasses.HV_220KV],
    [InterfaceHubGeoFeatures.ENERGEX_132KV, InterfaceHubGeoFeatureClasses.HV_132KV],
    [InterfaceHubGeoFeatures.ENERGEX_110KV, InterfaceHubGeoFeatureClasses.HV_132KV],
    [InterfaceHubGeoFeatures.ENERGEX_33KV_GROUP1, InterfaceHubGeoFeatureClasses.HV_33KV],
    [InterfaceHubGeoFeatures.ENERGEX_33KV_GROUP2, InterfaceHubGeoFeatureClasses.HV_33KV],
    [InterfaceHubGeoFeatures.ENERGEX_33KV_GROUP3, InterfaceHubGeoFeatureClasses.HV_33KV],
    [InterfaceHubGeoFeatures.ENERGEX_33KV_GROUP4, InterfaceHubGeoFeatureClasses.HV_33KV],
    [InterfaceHubGeoFeatures.ENERGEX_33KV_GROUP5, InterfaceHubGeoFeatureClasses.HV_33KV],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_CENTRAL_WEST_GROUP1, InterfaceHubGeoFeatureClasses.HV_11KV],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_CENTRAL_WEST_GROUP2, InterfaceHubGeoFeatureClasses.HV_11KV],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_CENTRAL_WEST_GROUP3, InterfaceHubGeoFeatureClasses.HV_11KV],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_CENTRAL_WEST_GROUP4, InterfaceHubGeoFeatureClasses.HV_11KV],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_METRO_NORTH_GROUP1, InterfaceHubGeoFeatureClasses.HV_11KV],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_METRO_NORTH_GROUP2, InterfaceHubGeoFeatureClasses.HV_11KV],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_METRO_NORTH_GROUP3, InterfaceHubGeoFeatureClasses.HV_11KV],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_METRO_NORTH_GROUP4, InterfaceHubGeoFeatureClasses.HV_11KV],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_METRO_NORTH_GROUP5, InterfaceHubGeoFeatureClasses.HV_11KV],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_METRO_NORTH_GROUP6, InterfaceHubGeoFeatureClasses.HV_11KV],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_METRO_NORTH_GROUP7, InterfaceHubGeoFeatureClasses.HV_11KV],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_METRO_NORTH_GROUP8, InterfaceHubGeoFeatureClasses.HV_11KV],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_METRO_NORTH_GROUP9, InterfaceHubGeoFeatureClasses.HV_11KV],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_METRO_NORTH_GROUP10, InterfaceHubGeoFeatureClasses.HV_11KV],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_METRO_NORTH_GROUP11, InterfaceHubGeoFeatureClasses.HV_11KV],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_METRO_NORTH_GROUP12, InterfaceHubGeoFeatureClasses.HV_11KV],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_METRO_NORTH_GROUP13, InterfaceHubGeoFeatureClasses.HV_11KV],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_METRO_NORTH_GROUP14, InterfaceHubGeoFeatureClasses.HV_11KV],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_METRO_NORTH_GROUP15, InterfaceHubGeoFeatureClasses.HV_11KV],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_METRO_SOUTH_GROUP1, InterfaceHubGeoFeatureClasses.HV_11KV],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_METRO_SOUTH_GROUP2, InterfaceHubGeoFeatureClasses.HV_11KV],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_METRO_SOUTH_GROUP3, InterfaceHubGeoFeatureClasses.HV_11KV],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_METRO_SOUTH_GROUP4, InterfaceHubGeoFeatureClasses.HV_11KV],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_METRO_SOUTH_GROUP5, InterfaceHubGeoFeatureClasses.HV_11KV],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_METRO_SOUTH_GROUP6, InterfaceHubGeoFeatureClasses.HV_11KV],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_METRO_SOUTH_GROUP7, InterfaceHubGeoFeatureClasses.HV_11KV],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_METRO_SOUTH_GROUP8, InterfaceHubGeoFeatureClasses.HV_11KV],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_METRO_SOUTH_GROUP9, InterfaceHubGeoFeatureClasses.HV_11KV],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_METRO_SOUTH_GROUP10, InterfaceHubGeoFeatureClasses.HV_11KV],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_METRO_SOUTH_GROUP11, InterfaceHubGeoFeatureClasses.HV_11KV],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_METRO_SOUTH_GROUP12, InterfaceHubGeoFeatureClasses.HV_11KV],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_METRO_SOUTH_GROUP13, InterfaceHubGeoFeatureClasses.HV_11KV],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_METRO_SOUTH_GROUP14, InterfaceHubGeoFeatureClasses.HV_11KV],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_METRO_SOUTH_GROUP15, InterfaceHubGeoFeatureClasses.HV_11KV],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_METRO_SOUTH_GROUP16, InterfaceHubGeoFeatureClasses.HV_11KV],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_NORTH_COAST_GROUP1, InterfaceHubGeoFeatureClasses.HV_11KV],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_NORTH_COAST_GROUP2, InterfaceHubGeoFeatureClasses.HV_11KV],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_NORTH_COAST_GROUP3, InterfaceHubGeoFeatureClasses.HV_11KV],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_NORTH_COAST_GROUP4, InterfaceHubGeoFeatureClasses.HV_11KV],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_NORTH_COAST_GROUP5, InterfaceHubGeoFeatureClasses.HV_11KV],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_NORTH_COAST_GROUP6, InterfaceHubGeoFeatureClasses.HV_11KV],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_NORTH_COAST_GROUP7, InterfaceHubGeoFeatureClasses.HV_11KV],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_NORTH_COAST_GROUP8, InterfaceHubGeoFeatureClasses.HV_11KV],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_NORTH_COAST_GROUP9, InterfaceHubGeoFeatureClasses.HV_11KV],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_NORTH_COAST_GROUP10, InterfaceHubGeoFeatureClasses.HV_11KV],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_NORTH_COAST_GROUP11, InterfaceHubGeoFeatureClasses.HV_11KV],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_NORTH_COAST_GROUP12, InterfaceHubGeoFeatureClasses.HV_11KV],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_NORTH_COAST_GROUP13, InterfaceHubGeoFeatureClasses.HV_11KV],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_NORTH_COAST_GROUP14, InterfaceHubGeoFeatureClasses.HV_11KV],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_SOUTH_COAST_GROUP1, InterfaceHubGeoFeatureClasses.HV_11KV],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_SOUTH_COAST_GROUP2, InterfaceHubGeoFeatureClasses.HV_11KV],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_SOUTH_COAST_GROUP3, InterfaceHubGeoFeatureClasses.HV_11KV],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_SOUTH_COAST_GROUP4, InterfaceHubGeoFeatureClasses.HV_11KV],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_SOUTH_COAST_GROUP5, InterfaceHubGeoFeatureClasses.HV_11KV],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_SOUTH_COAST_GROUP6, InterfaceHubGeoFeatureClasses.HV_11KV],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_SOUTH_COAST_GROUP7, InterfaceHubGeoFeatureClasses.HV_11KV],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_SOUTH_COAST_GROUP8, InterfaceHubGeoFeatureClasses.HV_11KV],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_SOUTH_COAST_GROUP9, InterfaceHubGeoFeatureClasses.HV_11KV],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_SOUTH_COAST_GROUP10, InterfaceHubGeoFeatureClasses.HV_11KV],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_WESTERN_GROUP1, InterfaceHubGeoFeatureClasses.HV_11KV],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_WESTERN_GROUP2, InterfaceHubGeoFeatureClasses.HV_11KV],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_WESTERN_GROUP3, InterfaceHubGeoFeatureClasses.HV_11KV],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_WESTERN_GROUP4, InterfaceHubGeoFeatureClasses.HV_11KV],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_WESTERN_GROUP5, InterfaceHubGeoFeatureClasses.HV_11KV],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_WESTERN_GROUP6, InterfaceHubGeoFeatureClasses.HV_11KV],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_WESTERN_GROUP7, InterfaceHubGeoFeatureClasses.HV_11KV],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_WESTERN_GROUP8, InterfaceHubGeoFeatureClasses.HV_11KV],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_WESTERN_GROUP9, InterfaceHubGeoFeatureClasses.HV_11KV],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_WESTERN_GROUP10, InterfaceHubGeoFeatureClasses.HV_11KV],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_WESTERN_GROUP11, InterfaceHubGeoFeatureClasses.HV_11KV],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_WESTERN_GROUP12, InterfaceHubGeoFeatureClasses.HV_11KV],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_WESTERN_GROUP13, InterfaceHubGeoFeatureClasses.HV_11KV],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_WESTERN_GROUP14, InterfaceHubGeoFeatureClasses.HV_11KV],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_WESTERN_GROUP15, InterfaceHubGeoFeatureClasses.HV_11KV],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_WESTERN_GROUP16, InterfaceHubGeoFeatureClasses.HV_11KV],
    [InterfaceHubGeoFeatures.ENERGEX_LV_CENTRAL_WEST_GROUP1, InterfaceHubGeoFeatureClasses.LV],
    [InterfaceHubGeoFeatures.ENERGEX_LV_CENTRAL_WEST_GROUP2, InterfaceHubGeoFeatureClasses.LV],
    [InterfaceHubGeoFeatures.ENERGEX_LV_CENTRAL_WEST_GROUP3, InterfaceHubGeoFeatureClasses.LV],
    [InterfaceHubGeoFeatures.ENERGEX_LV_CENTRAL_WEST_GROUP4, InterfaceHubGeoFeatureClasses.LV],
    [InterfaceHubGeoFeatures.ENERGEX_LV_CENTRAL_WEST_GROUP5, InterfaceHubGeoFeatureClasses.LV],
    [InterfaceHubGeoFeatures.ENERGEX_LV_CENTRAL_WEST_GROUP6, InterfaceHubGeoFeatureClasses.LV],
    [InterfaceHubGeoFeatures.ENERGEX_LV_METRO_NORTH_GROUP1, InterfaceHubGeoFeatureClasses.LV],
    [InterfaceHubGeoFeatures.ENERGEX_LV_METRO_NORTH_GROUP2, InterfaceHubGeoFeatureClasses.LV],
    [InterfaceHubGeoFeatures.ENERGEX_LV_METRO_NORTH_GROUP3, InterfaceHubGeoFeatureClasses.LV],
    [InterfaceHubGeoFeatures.ENERGEX_LV_METRO_NORTH_GROUP4, InterfaceHubGeoFeatureClasses.LV],
    [InterfaceHubGeoFeatures.ENERGEX_LV_METRO_NORTH_GROUP5, InterfaceHubGeoFeatureClasses.LV],
    [InterfaceHubGeoFeatures.ENERGEX_LV_METRO_NORTH_GROUP6, InterfaceHubGeoFeatureClasses.LV],
    [InterfaceHubGeoFeatures.ENERGEX_LV_METRO_NORTH_GROUP7, InterfaceHubGeoFeatureClasses.LV],
    [InterfaceHubGeoFeatures.ENERGEX_LV_METRO_NORTH_GROUP8, InterfaceHubGeoFeatureClasses.LV],
    [InterfaceHubGeoFeatures.ENERGEX_LV_METRO_NORTH_GROUP9, InterfaceHubGeoFeatureClasses.LV],
    [InterfaceHubGeoFeatures.ENERGEX_LV_METRO_NORTH_GROUP10, InterfaceHubGeoFeatureClasses.LV],
    [InterfaceHubGeoFeatures.ENERGEX_LV_METRO_NORTH_GROUP11, InterfaceHubGeoFeatureClasses.LV],
    [InterfaceHubGeoFeatures.ENERGEX_LV_METRO_NORTH_GROUP12, InterfaceHubGeoFeatureClasses.LV],
    [InterfaceHubGeoFeatures.ENERGEX_LV_METRO_NORTH_GROUP13, InterfaceHubGeoFeatureClasses.LV],
    [InterfaceHubGeoFeatures.ENERGEX_LV_METRO_SOUTH_GROUP1, InterfaceHubGeoFeatureClasses.LV],
    [InterfaceHubGeoFeatures.ENERGEX_LV_METRO_SOUTH_GROUP2, InterfaceHubGeoFeatureClasses.LV],
    [InterfaceHubGeoFeatures.ENERGEX_LV_METRO_SOUTH_GROUP3, InterfaceHubGeoFeatureClasses.LV],
    [InterfaceHubGeoFeatures.ENERGEX_LV_METRO_SOUTH_GROUP4, InterfaceHubGeoFeatureClasses.LV],
    [InterfaceHubGeoFeatures.ENERGEX_LV_METRO_SOUTH_GROUP5, InterfaceHubGeoFeatureClasses.LV],
    [InterfaceHubGeoFeatures.ENERGEX_LV_METRO_SOUTH_GROUP6, InterfaceHubGeoFeatureClasses.LV],
    [InterfaceHubGeoFeatures.ENERGEX_LV_METRO_SOUTH_GROUP7, InterfaceHubGeoFeatureClasses.LV],
    [InterfaceHubGeoFeatures.ENERGEX_LV_METRO_SOUTH_GROUP8, InterfaceHubGeoFeatureClasses.LV],
    [InterfaceHubGeoFeatures.ENERGEX_LV_METRO_SOUTH_GROUP9, InterfaceHubGeoFeatureClasses.LV],
    [InterfaceHubGeoFeatures.ENERGEX_LV_METRO_SOUTH_GROUP10, InterfaceHubGeoFeatureClasses.LV],
    [InterfaceHubGeoFeatures.ENERGEX_LV_METRO_SOUTH_GROUP11, InterfaceHubGeoFeatureClasses.LV],
    [InterfaceHubGeoFeatures.ENERGEX_LV_METRO_SOUTH_GROUP12, InterfaceHubGeoFeatureClasses.LV],
    [InterfaceHubGeoFeatures.ENERGEX_LV_METRO_SOUTH_GROUP13, InterfaceHubGeoFeatureClasses.LV],
    [InterfaceHubGeoFeatures.ENERGEX_LV_NORTH_COAST_GROUP1, InterfaceHubGeoFeatureClasses.LV],
    [InterfaceHubGeoFeatures.ENERGEX_LV_NORTH_COAST_GROUP2, InterfaceHubGeoFeatureClasses.LV],
    [InterfaceHubGeoFeatures.ENERGEX_LV_NORTH_COAST_GROUP3, InterfaceHubGeoFeatureClasses.LV],
    [InterfaceHubGeoFeatures.ENERGEX_LV_NORTH_COAST_GROUP4, InterfaceHubGeoFeatureClasses.LV],
    [InterfaceHubGeoFeatures.ENERGEX_LV_NORTH_COAST_GROUP5, InterfaceHubGeoFeatureClasses.LV],
    [InterfaceHubGeoFeatures.ENERGEX_LV_NORTH_COAST_GROUP6, InterfaceHubGeoFeatureClasses.LV],
    [InterfaceHubGeoFeatures.ENERGEX_LV_NORTH_COAST_GROUP7, InterfaceHubGeoFeatureClasses.LV],
    [InterfaceHubGeoFeatures.ENERGEX_LV_NORTH_COAST_GROUP8, InterfaceHubGeoFeatureClasses.LV],
    [InterfaceHubGeoFeatures.ENERGEX_LV_NORTH_COAST_GROUP9, InterfaceHubGeoFeatureClasses.LV],
    [InterfaceHubGeoFeatures.ENERGEX_LV_NORTH_COAST_GROUP10, InterfaceHubGeoFeatureClasses.LV],
    [InterfaceHubGeoFeatures.ENERGEX_LV_NORTH_COAST_GROUP11, InterfaceHubGeoFeatureClasses.LV],
    [InterfaceHubGeoFeatures.ENERGEX_LV_NORTH_COAST_GROUP12, InterfaceHubGeoFeatureClasses.LV],
    [InterfaceHubGeoFeatures.ENERGEX_LV_SOUTH_COAST_GROUP1, InterfaceHubGeoFeatureClasses.LV],
    [InterfaceHubGeoFeatures.ENERGEX_LV_SOUTH_COAST_GROUP2, InterfaceHubGeoFeatureClasses.LV],
    [InterfaceHubGeoFeatures.ENERGEX_LV_SOUTH_COAST_GROUP3, InterfaceHubGeoFeatureClasses.LV],
    [InterfaceHubGeoFeatures.ENERGEX_LV_SOUTH_COAST_GROUP4, InterfaceHubGeoFeatureClasses.LV],
    [InterfaceHubGeoFeatures.ENERGEX_LV_SOUTH_COAST_GROUP5, InterfaceHubGeoFeatureClasses.LV],
    [InterfaceHubGeoFeatures.ENERGEX_LV_SOUTH_COAST_GROUP6, InterfaceHubGeoFeatureClasses.LV],
    [InterfaceHubGeoFeatures.ENERGEX_LV_SOUTH_COAST_GROUP7, InterfaceHubGeoFeatureClasses.LV],
    [InterfaceHubGeoFeatures.ENERGEX_LV_SOUTH_COAST_GROUP8, InterfaceHubGeoFeatureClasses.LV],
    [InterfaceHubGeoFeatures.ENERGEX_LV_SOUTH_COAST_GROUP9, InterfaceHubGeoFeatureClasses.LV],
    [InterfaceHubGeoFeatures.ENERGEX_LV_WESTERN_GROUP1, InterfaceHubGeoFeatureClasses.LV],
    [InterfaceHubGeoFeatures.ENERGEX_LV_WESTERN_GROUP2, InterfaceHubGeoFeatureClasses.LV],
    [InterfaceHubGeoFeatures.ENERGEX_LV_WESTERN_GROUP3, InterfaceHubGeoFeatureClasses.LV],
    [InterfaceHubGeoFeatures.ENERGEX_LV_WESTERN_GROUP4, InterfaceHubGeoFeatureClasses.LV],
    [InterfaceHubGeoFeatures.ENERGEX_LV_WESTERN_GROUP5, InterfaceHubGeoFeatureClasses.LV],
    [InterfaceHubGeoFeatures.ENERGEX_LV_WESTERN_GROUP6, InterfaceHubGeoFeatureClasses.LV],
    [InterfaceHubGeoFeatures.ENERGEX_LV_WESTERN_GROUP7, InterfaceHubGeoFeatureClasses.LV],
    [InterfaceHubGeoFeatures.ENERGEX_LV_WESTERN_GROUP8, InterfaceHubGeoFeatureClasses.LV],
    [InterfaceHubGeoFeatures.ENERGEX_LV_WESTERN_GROUP9, InterfaceHubGeoFeatureClasses.LV],
    [InterfaceHubGeoFeatures.ENERGEX_LV_WESTERN_GROUP10, InterfaceHubGeoFeatureClasses.LV],
]);

export const CosecGeoFeatureNames = new Map([
	[InterfaceHubGeoFeatures.TRANSPOWER_TRANSMISSION_LINES, "Transpower ⁠— Transmission Lines"],
	[InterfaceHubGeoFeatures.TRANSPOWER_COOK_STRAIT, "Transpower ⁠— Cook Strait Protection Zone"],
	[InterfaceHubGeoFeatures.EQ_HV_CAPRICORNIA, "EQ ⁠— HV Capricornia"],
	[InterfaceHubGeoFeatures.EQ_HV_EE_GENERATION, "EQ ⁠— HV EE Generation"],
	[InterfaceHubGeoFeatures.EQ_HV_ERGON_ENERGY, "EQ ⁠— HV Ergon Energy"],
	[InterfaceHubGeoFeatures.EQ_HV_FAR_NORTH, "EQ ⁠— HV Far North"],
	[InterfaceHubGeoFeatures.EQ_HV_MACKAY, "EQ ⁠— HV Mackay"],
	[InterfaceHubGeoFeatures.EQ_HV_NORTH_QUEENSLAND, "EQ ⁠— HV North Queensland"],
	[InterfaceHubGeoFeatures.EQ_HV_SOUTH_WEST, "EQ ⁠— HV South West"],
	[InterfaceHubGeoFeatures.EQ_HV_WIDE_BAY, "EQ ⁠— HV Wide Bay"],
	[InterfaceHubGeoFeatures.ENERGEX_132KV, "Energex ⁠— 132kV"],
	[InterfaceHubGeoFeatures.ENERGEX_110KV, "Energex ⁠— 110kV"],
	[InterfaceHubGeoFeatures.ENERGEX_33KV_GROUP1, "Energex ⁠— 33kV — Group 1"],
	[InterfaceHubGeoFeatures.ENERGEX_33KV_GROUP2, "Energex ⁠— 33kV — Group 2"],
	[InterfaceHubGeoFeatures.ENERGEX_33KV_GROUP3, "Energex ⁠— 33kV — Group 3"],
	[InterfaceHubGeoFeatures.ENERGEX_33KV_GROUP4, "Energex ⁠— 33kV — Group 4"],
	[InterfaceHubGeoFeatures.ENERGEX_33KV_GROUP5, "Energex ⁠— 33kV — Group 5"],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_CENTRAL_WEST_GROUP1, "Energex ⁠— 11kV — Central West Group 1"],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_CENTRAL_WEST_GROUP2, "Energex ⁠— 11kV — Central West Group 2"],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_CENTRAL_WEST_GROUP3, "Energex ⁠— 11kV — Central West Group 3"],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_CENTRAL_WEST_GROUP4, "Energex ⁠— 11kV — Central West Group 4"],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_METRO_NORTH_GROUP1, "Energex ⁠— 11kV — Metro North Group 1"],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_METRO_NORTH_GROUP2, "Energex ⁠— 11kV — Metro North Group 2"],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_METRO_NORTH_GROUP3, "Energex ⁠— 11kV — Metro North Group 3"],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_METRO_NORTH_GROUP4, "Energex ⁠— 11kV — Metro North Group 4"],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_METRO_NORTH_GROUP5, "Energex ⁠— 11kV — Metro North Group 5"],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_METRO_NORTH_GROUP6, "Energex ⁠— 11kV — Metro North Group 6"],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_METRO_NORTH_GROUP7, "Energex ⁠— 11kV — Metro North Group 7"],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_METRO_NORTH_GROUP8, "Energex ⁠— 11kV — Metro North Group 8"],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_METRO_NORTH_GROUP9, "Energex ⁠— 11kV — Metro North Group 9"],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_METRO_NORTH_GROUP10, "Energex ⁠— 11kV — Metro North Group 10"],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_METRO_NORTH_GROUP11, "Energex ⁠— 11kV — Metro North Group 11"],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_METRO_NORTH_GROUP12, "Energex ⁠— 11kV — Metro North Group 12"],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_METRO_NORTH_GROUP13, "Energex ⁠— 11kV — Metro North Group 13"],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_METRO_NORTH_GROUP14, "Energex ⁠— 11kV — Metro North Group 14"],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_METRO_NORTH_GROUP15, "Energex ⁠— 11kV — Metro North Group 15"],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_METRO_SOUTH_GROUP1, "Energex ⁠— 11kV — Metro North Group 1"],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_METRO_SOUTH_GROUP2, "Energex ⁠— 11kV — Metro North Group 2"],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_METRO_SOUTH_GROUP3, "Energex ⁠— 11kV — Metro North Group 3"],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_METRO_SOUTH_GROUP4, "Energex ⁠— 11kV — Metro North Group 4"],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_METRO_SOUTH_GROUP5, "Energex ⁠— 11kV — Metro North Group 5"],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_METRO_SOUTH_GROUP6, "Energex ⁠— 11kV — Metro North Group 6"],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_METRO_SOUTH_GROUP7, "Energex ⁠— 11kV — Metro North Group 7"],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_METRO_SOUTH_GROUP8, "Energex ⁠— 11kV — Metro North Group 8"],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_METRO_SOUTH_GROUP9, "Energex ⁠— 11kV — Metro North Group 9"],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_METRO_SOUTH_GROUP10, "Energex ⁠— 11kV — Metro North Group 10"],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_METRO_SOUTH_GROUP11, "Energex ⁠— 11kV — Metro North Group 11"],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_METRO_SOUTH_GROUP12, "Energex ⁠— 11kV — Metro North Group 12"],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_METRO_SOUTH_GROUP13, "Energex ⁠— 11kV — Metro North Group 13"],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_METRO_SOUTH_GROUP14, "Energex ⁠— 11kV — Metro North Group 14"],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_METRO_SOUTH_GROUP15, "Energex ⁠— 11kV — Metro North Group 15"],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_METRO_SOUTH_GROUP16, "Energex ⁠— 11kV — Metro North Group 16"],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_NORTH_COAST_GROUP1, "Energex ⁠— 11kV — North Coast Group 1"],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_NORTH_COAST_GROUP2, "Energex ⁠— 11kV — North Coast Group 2"],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_NORTH_COAST_GROUP3, "Energex ⁠— 11kV — North Coast Group 3"],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_NORTH_COAST_GROUP4, "Energex ⁠— 11kV — North Coast Group 4"],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_NORTH_COAST_GROUP5, "Energex ⁠— 11kV — North Coast Group 5"],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_NORTH_COAST_GROUP6, "Energex ⁠— 11kV — North Coast Group 6"],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_NORTH_COAST_GROUP7, "Energex ⁠— 11kV — North Coast Group 7"],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_NORTH_COAST_GROUP8, "Energex ⁠— 11kV — North Coast Group 8"],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_NORTH_COAST_GROUP9, "Energex ⁠— 11kV — North Coast Group 9"],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_NORTH_COAST_GROUP10, "Energex ⁠— 11kV — North Coast Group 10"],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_NORTH_COAST_GROUP11, "Energex ⁠— 11kV — North Coast Group 11"],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_NORTH_COAST_GROUP12, "Energex ⁠— 11kV — North Coast Group 12"],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_NORTH_COAST_GROUP13, "Energex ⁠— 11kV — North Coast Group 13"],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_NORTH_COAST_GROUP14, "Energex ⁠— 11kV — North Coast Group 14"],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_SOUTH_COAST_GROUP1, "Energex ⁠— 11kV — South Coast Group 1"],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_SOUTH_COAST_GROUP2, "Energex ⁠— 11kV — South Coast Group 2"],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_SOUTH_COAST_GROUP3, "Energex ⁠— 11kV — South Coast Group 3"],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_SOUTH_COAST_GROUP4, "Energex ⁠— 11kV — South Coast Group 4"],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_SOUTH_COAST_GROUP5, "Energex ⁠— 11kV — South Coast Group 5"],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_SOUTH_COAST_GROUP6, "Energex ⁠— 11kV — South Coast Group 6"],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_SOUTH_COAST_GROUP7, "Energex ⁠— 11kV — South Coast Group 7"],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_SOUTH_COAST_GROUP8, "Energex ⁠— 11kV — South Coast Group 8"],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_SOUTH_COAST_GROUP9, "Energex ⁠— 11kV — South Coast Group 9"],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_SOUTH_COAST_GROUP10, "Energex ⁠— 11kV — South Coast Group 10"],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_WESTERN_GROUP1, "Energex ⁠— 11kV — Western Group 1"],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_WESTERN_GROUP2, "Energex ⁠— 11kV — Western Group 2"],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_WESTERN_GROUP3, "Energex ⁠— 11kV — Western Group 3"],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_WESTERN_GROUP4, "Energex ⁠— 11kV — Western Group 4"],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_WESTERN_GROUP5, "Energex ⁠— 11kV — Western Group 5"],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_WESTERN_GROUP6, "Energex ⁠— 11kV — Western Group 6"],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_WESTERN_GROUP7, "Energex ⁠— 11kV — Western Group 7"],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_WESTERN_GROUP8, "Energex ⁠— 11kV — Western Group 8"],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_WESTERN_GROUP9, "Energex ⁠— 11kV — Western Group 9"],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_WESTERN_GROUP10, "Energex ⁠— 11kV — Western Group 10"],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_WESTERN_GROUP11, "Energex ⁠— 11kV — Western Group 11"],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_WESTERN_GROUP12, "Energex ⁠— 11kV — Western Group 12"],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_WESTERN_GROUP13, "Energex ⁠— 11kV — Western Group 13"],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_WESTERN_GROUP14, "Energex ⁠— 11kV — Western Group 14"],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_WESTERN_GROUP15, "Energex ⁠— 11kV — Western Group 15"],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_WESTERN_GROUP16, "Energex ⁠— 11kV — Western Group 16"],
    [InterfaceHubGeoFeatures.ENERGEX_LV_CENTRAL_WEST_GROUP1, "Energex ⁠— LV — Central West Group 1"],
    [InterfaceHubGeoFeatures.ENERGEX_LV_CENTRAL_WEST_GROUP2, "Energex ⁠— LV — Central West Group 2"],
    [InterfaceHubGeoFeatures.ENERGEX_LV_CENTRAL_WEST_GROUP3, "Energex ⁠— LV — Central West Group 3"],
    [InterfaceHubGeoFeatures.ENERGEX_LV_CENTRAL_WEST_GROUP4, "Energex ⁠— LV — Central West Group 4"],
    [InterfaceHubGeoFeatures.ENERGEX_LV_CENTRAL_WEST_GROUP5, "Energex ⁠— LV — Central West Group 5"],
    [InterfaceHubGeoFeatures.ENERGEX_LV_CENTRAL_WEST_GROUP6, "Energex ⁠— LV — Central West Group 6"],
    [InterfaceHubGeoFeatures.ENERGEX_LV_METRO_NORTH_GROUP1, "Energex ⁠— LV — Metro North Group 1"],
    [InterfaceHubGeoFeatures.ENERGEX_LV_METRO_NORTH_GROUP2, "Energex ⁠— LV — Metro North Group 2"],
    [InterfaceHubGeoFeatures.ENERGEX_LV_METRO_NORTH_GROUP3, "Energex ⁠— LV — Metro North Group 3"],
    [InterfaceHubGeoFeatures.ENERGEX_LV_METRO_NORTH_GROUP4, "Energex ⁠— LV — Metro North Group 4"],
    [InterfaceHubGeoFeatures.ENERGEX_LV_METRO_NORTH_GROUP5, "Energex ⁠— LV — Metro North Group 5"],
    [InterfaceHubGeoFeatures.ENERGEX_LV_METRO_NORTH_GROUP6, "Energex ⁠— LV — Metro North Group 6"],
    [InterfaceHubGeoFeatures.ENERGEX_LV_METRO_NORTH_GROUP7, "Energex ⁠— LV — Metro North Group 7"],
    [InterfaceHubGeoFeatures.ENERGEX_LV_METRO_NORTH_GROUP8, "Energex ⁠— LV — Metro North Group 8"],
    [InterfaceHubGeoFeatures.ENERGEX_LV_METRO_NORTH_GROUP9, "Energex ⁠— LV — Metro North Group 9"],
    [InterfaceHubGeoFeatures.ENERGEX_LV_METRO_NORTH_GROUP10, "Energex ⁠— LV — Metro North Group 10"],
    [InterfaceHubGeoFeatures.ENERGEX_LV_METRO_NORTH_GROUP11, "Energex ⁠— LV — Metro North Group 11"],
    [InterfaceHubGeoFeatures.ENERGEX_LV_METRO_NORTH_GROUP12, "Energex ⁠— LV — Metro North Group 12"],
    [InterfaceHubGeoFeatures.ENERGEX_LV_METRO_NORTH_GROUP13, "Energex ⁠— LV — Metro North Group 13"],
    [InterfaceHubGeoFeatures.ENERGEX_LV_METRO_SOUTH_GROUP1, "Energex ⁠— LV — Metro South Group 1"],
    [InterfaceHubGeoFeatures.ENERGEX_LV_METRO_SOUTH_GROUP2, "Energex ⁠— LV — Metro South Group 2"],
    [InterfaceHubGeoFeatures.ENERGEX_LV_METRO_SOUTH_GROUP3, "Energex ⁠— LV — Metro South Group 3"],
    [InterfaceHubGeoFeatures.ENERGEX_LV_METRO_SOUTH_GROUP4, "Energex ⁠— LV — Metro South Group 4"],
    [InterfaceHubGeoFeatures.ENERGEX_LV_METRO_SOUTH_GROUP5, "Energex ⁠— LV — Metro South Group 5"],
    [InterfaceHubGeoFeatures.ENERGEX_LV_METRO_SOUTH_GROUP6, "Energex ⁠— LV — Metro South Group 6"],
    [InterfaceHubGeoFeatures.ENERGEX_LV_METRO_SOUTH_GROUP7, "Energex ⁠— LV — Metro South Group 7"],
    [InterfaceHubGeoFeatures.ENERGEX_LV_METRO_SOUTH_GROUP8, "Energex ⁠— LV — Metro South Group 8"],
    [InterfaceHubGeoFeatures.ENERGEX_LV_METRO_SOUTH_GROUP9, "Energex ⁠— LV — Metro South Group 9"],
    [InterfaceHubGeoFeatures.ENERGEX_LV_METRO_SOUTH_GROUP10, "Energex ⁠— LV — Metro South Group 10"],
    [InterfaceHubGeoFeatures.ENERGEX_LV_METRO_SOUTH_GROUP11, "Energex ⁠— LV — Metro South Group 11"],
    [InterfaceHubGeoFeatures.ENERGEX_LV_METRO_SOUTH_GROUP12, "Energex ⁠— LV — Metro South Group 12"],
    [InterfaceHubGeoFeatures.ENERGEX_LV_METRO_SOUTH_GROUP13, "Energex ⁠— LV — Metro South Group 13"],
    [InterfaceHubGeoFeatures.ENERGEX_LV_NORTH_COAST_GROUP1, "Energex ⁠— LV — North Coast Group 1"],
    [InterfaceHubGeoFeatures.ENERGEX_LV_NORTH_COAST_GROUP2, "Energex ⁠— LV — North Coast Group 2"],
    [InterfaceHubGeoFeatures.ENERGEX_LV_NORTH_COAST_GROUP3, "Energex ⁠— LV — North Coast Group 3"],
    [InterfaceHubGeoFeatures.ENERGEX_LV_NORTH_COAST_GROUP4, "Energex ⁠— LV — North Coast Group 4"],
    [InterfaceHubGeoFeatures.ENERGEX_LV_NORTH_COAST_GROUP5, "Energex ⁠— LV — North Coast Group 5"],
    [InterfaceHubGeoFeatures.ENERGEX_LV_NORTH_COAST_GROUP6, "Energex ⁠— LV — North Coast Group 6"],
    [InterfaceHubGeoFeatures.ENERGEX_LV_NORTH_COAST_GROUP7, "Energex ⁠— LV — North Coast Group 7"],
    [InterfaceHubGeoFeatures.ENERGEX_LV_NORTH_COAST_GROUP8, "Energex ⁠— LV — North Coast Group 8"],
    [InterfaceHubGeoFeatures.ENERGEX_LV_NORTH_COAST_GROUP9, "Energex ⁠— LV — North Coast Group 9"],
    [InterfaceHubGeoFeatures.ENERGEX_LV_NORTH_COAST_GROUP10, "Energex ⁠— LV — North Coast Group 10"],
    [InterfaceHubGeoFeatures.ENERGEX_LV_NORTH_COAST_GROUP11, "Energex ⁠— LV — North Coast Group 11"],
    [InterfaceHubGeoFeatures.ENERGEX_LV_NORTH_COAST_GROUP12, "Energex ⁠— LV — North Coast Group 12"],
    [InterfaceHubGeoFeatures.ENERGEX_LV_SOUTH_COAST_GROUP1, "Energex ⁠— LV — South Coast Group 1"],
    [InterfaceHubGeoFeatures.ENERGEX_LV_SOUTH_COAST_GROUP2, "Energex ⁠— LV — South Coast Group 2"],
    [InterfaceHubGeoFeatures.ENERGEX_LV_SOUTH_COAST_GROUP3, "Energex ⁠— LV — South Coast Group 3"],
    [InterfaceHubGeoFeatures.ENERGEX_LV_SOUTH_COAST_GROUP4, "Energex ⁠— LV — South Coast Group 4"],
    [InterfaceHubGeoFeatures.ENERGEX_LV_SOUTH_COAST_GROUP5, "Energex ⁠— LV — South Coast Group 5"],
    [InterfaceHubGeoFeatures.ENERGEX_LV_SOUTH_COAST_GROUP6, "Energex ⁠— LV — South Coast Group 6"],
    [InterfaceHubGeoFeatures.ENERGEX_LV_SOUTH_COAST_GROUP7, "Energex ⁠— LV — South Coast Group 7"],
    [InterfaceHubGeoFeatures.ENERGEX_LV_SOUTH_COAST_GROUP8, "Energex ⁠— LV — South Coast Group 8"],
    [InterfaceHubGeoFeatures.ENERGEX_LV_SOUTH_COAST_GROUP9, "Energex ⁠— LV — South Coast Group 9"],
    [InterfaceHubGeoFeatures.ENERGEX_LV_WESTERN_GROUP1, "Energex ⁠— LV — Western Group 1"],
    [InterfaceHubGeoFeatures.ENERGEX_LV_WESTERN_GROUP2, "Energex ⁠— LV — Western Group 2"],
    [InterfaceHubGeoFeatures.ENERGEX_LV_WESTERN_GROUP3, "Energex ⁠— LV — Western Group 3"],
    [InterfaceHubGeoFeatures.ENERGEX_LV_WESTERN_GROUP4, "Energex ⁠— LV — Western Group 4"],
    [InterfaceHubGeoFeatures.ENERGEX_LV_WESTERN_GROUP5, "Energex ⁠— LV — Western Group 5"],
    [InterfaceHubGeoFeatures.ENERGEX_LV_WESTERN_GROUP6, "Energex ⁠— LV — Western Group 6"],
    [InterfaceHubGeoFeatures.ENERGEX_LV_WESTERN_GROUP7, "Energex ⁠— LV — Western Group 7"],
    [InterfaceHubGeoFeatures.ENERGEX_LV_WESTERN_GROUP8, "Energex ⁠— LV — Western Group 8"],
    [InterfaceHubGeoFeatures.ENERGEX_LV_WESTERN_GROUP9, "Energex ⁠— LV — Western Group 9"],
    [InterfaceHubGeoFeatures.ENERGEX_LV_WESTERN_GROUP10, "Energex ⁠— LV — Western Group 10"],
]);

export const CosecGeoFeatureFiles = new Map([
	[InterfaceHubGeoFeatures.TRANSPOWER_TRANSMISSION_LINES, '/data_overlays/transpower/Transmission_Lines.v1.geojson'],
	[InterfaceHubGeoFeatures.TRANSPOWER_COOK_STRAIT, '/data_overlays/transpower/Cook_Strait_Protection_Zone.v1.geojson'],
	[InterfaceHubGeoFeatures.EQ_HV_CAPRICORNIA, '/data_overlays/energy_queensland/Network_HV/Network_HV_Capricornia.v1.geojson'],
	[InterfaceHubGeoFeatures.EQ_HV_EE_GENERATION, '/data_overlays/energy_queensland/Network_HV/Network_HV_EE_Generation.v1.geojson'],
	[InterfaceHubGeoFeatures.EQ_HV_ERGON_ENERGY, '/data_overlays/energy_queensland/Network_HV/Network_HV_Ergon_Energy.v1.geojson'],
	[InterfaceHubGeoFeatures.EQ_HV_FAR_NORTH, '/data_overlays/energy_queensland/Network_HV/Network_HV_Far_North.v1.geojson'],
	[InterfaceHubGeoFeatures.EQ_HV_MACKAY, '/data_overlays/energy_queensland/Network_HV/Network_HV_Mackay.v1.geojson'],
	[InterfaceHubGeoFeatures.EQ_HV_NORTH_QUEENSLAND, '/data_overlays/energy_queensland/Network_HV/Network_HV_North_Queensland.v1.geojson'],
	[InterfaceHubGeoFeatures.EQ_HV_SOUTH_WEST, '/data_overlays/energy_queensland/Network_HV/Network_HV_South_West.v1.geojson'],
	[InterfaceHubGeoFeatures.EQ_HV_WIDE_BAY, '/data_overlays/energy_queensland/Network_HV/Network_HV_Wide_Bay.v1.geojson'],
	[InterfaceHubGeoFeatures.ENERGEX_132KV, '/data_overlays/energex/Network_132kv.v1.geojson'],
	[InterfaceHubGeoFeatures.ENERGEX_110KV, '/data_overlays/energex/Network_110kv.v1.geojson'],
	[InterfaceHubGeoFeatures.ENERGEX_33KV_GROUP1, '/data_overlays/energex/Network_33kV/Network_33kv_Group_1.v1.geojson'],
	[InterfaceHubGeoFeatures.ENERGEX_33KV_GROUP2, '/data_overlays/energex/Network_33kV/Network_33kv_Group_2.v1.geojson'],
	[InterfaceHubGeoFeatures.ENERGEX_33KV_GROUP3, '/data_overlays/energex/Network_33kV/Network_33kv_Group_3.v1.geojson'],
	[InterfaceHubGeoFeatures.ENERGEX_33KV_GROUP4, '/data_overlays/energex/Network_33kV/Network_33kv_Group_4.v1.geojson'],
	[InterfaceHubGeoFeatures.ENERGEX_33KV_GROUP5, '/data_overlays/energex/Network_33kV/Network_33kv_Group_5.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_CENTRAL_WEST_GROUP1, '/data_overlays/energex/Network_11kV/Network_11kv_Central_West_Group_1.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_CENTRAL_WEST_GROUP2, '/data_overlays/energex/Network_11kV/Network_11kv_Central_West_Group_2.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_CENTRAL_WEST_GROUP3, '/data_overlays/energex/Network_11kV/Network_11kv_Central_West_Group_3.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_CENTRAL_WEST_GROUP4, '/data_overlays/energex/Network_11kV/Network_11kv_Central_West_Group_4.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_METRO_NORTH_GROUP1, '/data_overlays/energex/Network_11kV/Network_11kv_Metro_North_Group_1.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_METRO_NORTH_GROUP2, '/data_overlays/energex/Network_11kV/Network_11kv_Metro_North_Group_2.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_METRO_NORTH_GROUP3, '/data_overlays/energex/Network_11kV/Network_11kv_Metro_North_Group_3.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_METRO_NORTH_GROUP4, '/data_overlays/energex/Network_11kV/Network_11kv_Metro_North_Group_4.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_METRO_NORTH_GROUP5, '/data_overlays/energex/Network_11kV/Network_11kv_Metro_North_Group_5.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_METRO_NORTH_GROUP6, '/data_overlays/energex/Network_11kV/Network_11kv_Metro_North_Group_6.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_METRO_NORTH_GROUP7, '/data_overlays/energex/Network_11kV/Network_11kv_Metro_North_Group_7.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_METRO_NORTH_GROUP8, '/data_overlays/energex/Network_11kV/Network_11kv_Metro_North_Group_8.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_METRO_NORTH_GROUP9, '/data_overlays/energex/Network_11kV/Network_11kv_Metro_North_Group_9.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_METRO_NORTH_GROUP10, '/data_overlays/energex/Network_11kV/Network_11kv_Metro_North_Group_10.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_METRO_NORTH_GROUP11, '/data_overlays/energex/Network_11kV/Network_11kv_Metro_North_Group_11.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_METRO_NORTH_GROUP12, '/data_overlays/energex/Network_11kV/Network_11kv_Metro_North_Group_12.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_METRO_NORTH_GROUP13, '/data_overlays/energex/Network_11kV/Network_11kv_Metro_North_Group_13.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_METRO_NORTH_GROUP14, '/data_overlays/energex/Network_11kV/Network_11kv_Metro_North_Group_14.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_METRO_NORTH_GROUP15, '/data_overlays/energex/Network_11kV/Network_11kv_Metro_North_Group_15.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_METRO_SOUTH_GROUP1, '/data_overlays/energex/Network_11kV/Network_11kv_Metro_South_Group_1.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_METRO_SOUTH_GROUP2, '/data_overlays/energex/Network_11kV/Network_11kv_Metro_South_Group_2.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_METRO_SOUTH_GROUP3, '/data_overlays/energex/Network_11kV/Network_11kv_Metro_South_Group_3.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_METRO_SOUTH_GROUP4, '/data_overlays/energex/Network_11kV/Network_11kv_Metro_South_Group_4.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_METRO_SOUTH_GROUP5, '/data_overlays/energex/Network_11kV/Network_11kv_Metro_South_Group_5.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_METRO_SOUTH_GROUP6, '/data_overlays/energex/Network_11kV/Network_11kv_Metro_South_Group_6.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_METRO_SOUTH_GROUP7, '/data_overlays/energex/Network_11kV/Network_11kv_Metro_South_Group_7.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_METRO_SOUTH_GROUP8, '/data_overlays/energex/Network_11kV/Network_11kv_Metro_South_Group_8.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_METRO_SOUTH_GROUP9, '/data_overlays/energex/Network_11kV/Network_11kv_Metro_South_Group_9.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_METRO_SOUTH_GROUP10, '/data_overlays/energex/Network_11kV/Network_11kv_Metro_South_Group_10.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_METRO_SOUTH_GROUP11, '/data_overlays/energex/Network_11kV/Network_11kv_Metro_South_Group_11.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_METRO_SOUTH_GROUP12, '/data_overlays/energex/Network_11kV/Network_11kv_Metro_South_Group_12.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_METRO_SOUTH_GROUP13, '/data_overlays/energex/Network_11kV/Network_11kv_Metro_South_Group_13.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_METRO_SOUTH_GROUP14, '/data_overlays/energex/Network_11kV/Network_11kv_Metro_South_Group_14.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_METRO_SOUTH_GROUP15, '/data_overlays/energex/Network_11kV/Network_11kv_Metro_South_Group_15.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_METRO_SOUTH_GROUP16, '/data_overlays/energex/Network_11kV/Network_11kv_Metro_South_Group_16.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_NORTH_COAST_GROUP1, '/data_overlays/energex/Network_11kV/Network_11kv_North_Coast_Group_1.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_NORTH_COAST_GROUP2, '/data_overlays/energex/Network_11kV/Network_11kv_North_Coast_Group_2.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_NORTH_COAST_GROUP3, '/data_overlays/energex/Network_11kV/Network_11kv_North_Coast_Group_3.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_NORTH_COAST_GROUP4, '/data_overlays/energex/Network_11kV/Network_11kv_North_Coast_Group_4.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_NORTH_COAST_GROUP5, '/data_overlays/energex/Network_11kV/Network_11kv_North_Coast_Group_5.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_NORTH_COAST_GROUP6, '/data_overlays/energex/Network_11kV/Network_11kv_North_Coast_Group_6.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_NORTH_COAST_GROUP7, '/data_overlays/energex/Network_11kV/Network_11kv_North_Coast_Group_7.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_NORTH_COAST_GROUP8, '/data_overlays/energex/Network_11kV/Network_11kv_North_Coast_Group_8.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_NORTH_COAST_GROUP9, '/data_overlays/energex/Network_11kV/Network_11kv_North_Coast_Group_9.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_NORTH_COAST_GROUP10, '/data_overlays/energex/Network_11kV/Network_11kv_North_Coast_Group_10.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_NORTH_COAST_GROUP11, '/data_overlays/energex/Network_11kV/Network_11kv_North_Coast_Group_11.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_NORTH_COAST_GROUP12, '/data_overlays/energex/Network_11kV/Network_11kv_North_Coast_Group_12.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_NORTH_COAST_GROUP13, '/data_overlays/energex/Network_11kV/Network_11kv_North_Coast_Group_13.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_NORTH_COAST_GROUP14, '/data_overlays/energex/Network_11kV/Network_11kv_North_Coast_Group_14.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_SOUTH_COAST_GROUP1, '/data_overlays/energex/Network_11kV/Network_11kv_South_Coast_Group_1.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_SOUTH_COAST_GROUP2, '/data_overlays/energex/Network_11kV/Network_11kv_South_Coast_Group_2.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_SOUTH_COAST_GROUP3, '/data_overlays/energex/Network_11kV/Network_11kv_South_Coast_Group_3.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_SOUTH_COAST_GROUP4, '/data_overlays/energex/Network_11kV/Network_11kv_South_Coast_Group_4.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_SOUTH_COAST_GROUP5, '/data_overlays/energex/Network_11kV/Network_11kv_South_Coast_Group_5.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_SOUTH_COAST_GROUP6, '/data_overlays/energex/Network_11kV/Network_11kv_South_Coast_Group_6.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_SOUTH_COAST_GROUP7, '/data_overlays/energex/Network_11kV/Network_11kv_South_Coast_Group_7.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_SOUTH_COAST_GROUP8, '/data_overlays/energex/Network_11kV/Network_11kv_South_Coast_Group_8.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_SOUTH_COAST_GROUP9, '/data_overlays/energex/Network_11kV/Network_11kv_South_Coast_Group_9.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_SOUTH_COAST_GROUP10, '/data_overlays/energex/Network_11kV/Network_11kv_South_Coast_Group_10.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_WESTERN_GROUP1, '/data_overlays/energex/Network_11kV/Network_11kv_Western_Group_1.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_WESTERN_GROUP2, '/data_overlays/energex/Network_11kV/Network_11kv_Western_Group_2.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_WESTERN_GROUP3, '/data_overlays/energex/Network_11kV/Network_11kv_Western_Group_3.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_WESTERN_GROUP4, '/data_overlays/energex/Network_11kV/Network_11kv_Western_Group_4.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_WESTERN_GROUP5, '/data_overlays/energex/Network_11kV/Network_11kv_Western_Group_5.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_WESTERN_GROUP6, '/data_overlays/energex/Network_11kV/Network_11kv_Western_Group_6.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_WESTERN_GROUP7, '/data_overlays/energex/Network_11kV/Network_11kv_Western_Group_7.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_WESTERN_GROUP8, '/data_overlays/energex/Network_11kV/Network_11kv_Western_Group_8.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_WESTERN_GROUP9, '/data_overlays/energex/Network_11kV/Network_11kv_Western_Group_9.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_WESTERN_GROUP10, '/data_overlays/energex/Network_11kV/Network_11kv_Western_Group_10.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_WESTERN_GROUP11, '/data_overlays/energex/Network_11kV/Network_11kv_Western_Group_11.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_WESTERN_GROUP12, '/data_overlays/energex/Network_11kV/Network_11kv_Western_Group_12.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_WESTERN_GROUP13, '/data_overlays/energex/Network_11kV/Network_11kv_Western_Group_13.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_WESTERN_GROUP14, '/data_overlays/energex/Network_11kV/Network_11kv_Western_Group_14.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_WESTERN_GROUP15, '/data_overlays/energex/Network_11kV/Network_11kv_Western_Group_15.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_11KV_WESTERN_GROUP16, '/data_overlays/energex/Network_11kV/Network_11kv_Western_Group_16.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_LV_CENTRAL_WEST_GROUP1, '/data_overlays/energex/Network_LV/Network_LV_Central_West_Group_1.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_LV_CENTRAL_WEST_GROUP2, '/data_overlays/energex/Network_LV/Network_LV_Central_West_Group_2.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_LV_CENTRAL_WEST_GROUP3, '/data_overlays/energex/Network_LV/Network_LV_Central_West_Group_3.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_LV_CENTRAL_WEST_GROUP4, '/data_overlays/energex/Network_LV/Network_LV_Central_West_Group_4.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_LV_CENTRAL_WEST_GROUP5, '/data_overlays/energex/Network_LV/Network_LV_Central_West_Group_5.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_LV_CENTRAL_WEST_GROUP6, '/data_overlays/energex/Network_LV/Network_LV_Central_West_Group_6.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_LV_METRO_NORTH_GROUP1, '/data_overlays/energex/Network_LV/Network_LV_Metro_North_Group_1.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_LV_METRO_NORTH_GROUP2, '/data_overlays/energex/Network_LV/Network_LV_Metro_North_Group_2.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_LV_METRO_NORTH_GROUP3, '/data_overlays/energex/Network_LV/Network_LV_Metro_North_Group_3.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_LV_METRO_NORTH_GROUP4, '/data_overlays/energex/Network_LV/Network_LV_Metro_North_Group_4.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_LV_METRO_NORTH_GROUP5, '/data_overlays/energex/Network_LV/Network_LV_Metro_North_Group_5.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_LV_METRO_NORTH_GROUP6, '/data_overlays/energex/Network_LV/Network_LV_Metro_North_Group_6.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_LV_METRO_NORTH_GROUP7, '/data_overlays/energex/Network_LV/Network_LV_Metro_North_Group_7.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_LV_METRO_NORTH_GROUP8, '/data_overlays/energex/Network_LV/Network_LV_Metro_North_Group_8.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_LV_METRO_NORTH_GROUP9, '/data_overlays/energex/Network_LV/Network_LV_Metro_North_Group_9.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_LV_METRO_NORTH_GROUP10, '/data_overlays/energex/Network_LV/Network_LV_Metro_North_Group_10.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_LV_METRO_NORTH_GROUP11, '/data_overlays/energex/Network_LV/Network_LV_Metro_North_Group_11.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_LV_METRO_NORTH_GROUP12, '/data_overlays/energex/Network_LV/Network_LV_Metro_North_Group_12.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_LV_METRO_NORTH_GROUP13, '/data_overlays/energex/Network_LV/Network_LV_Metro_North_Group_13.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_LV_METRO_SOUTH_GROUP1, '/data_overlays/energex/Network_LV/Network_LV_Metro_South_Group_1.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_LV_METRO_SOUTH_GROUP2, '/data_overlays/energex/Network_LV/Network_LV_Metro_South_Group_2.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_LV_METRO_SOUTH_GROUP3, '/data_overlays/energex/Network_LV/Network_LV_Metro_South_Group_3.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_LV_METRO_SOUTH_GROUP4, '/data_overlays/energex/Network_LV/Network_LV_Metro_South_Group_4.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_LV_METRO_SOUTH_GROUP5, '/data_overlays/energex/Network_LV/Network_LV_Metro_South_Group_5.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_LV_METRO_SOUTH_GROUP6, '/data_overlays/energex/Network_LV/Network_LV_Metro_South_Group_6.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_LV_METRO_SOUTH_GROUP7, '/data_overlays/energex/Network_LV/Network_LV_Metro_South_Group_7.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_LV_METRO_SOUTH_GROUP8, '/data_overlays/energex/Network_LV/Network_LV_Metro_South_Group_8.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_LV_METRO_SOUTH_GROUP9, '/data_overlays/energex/Network_LV/Network_LV_Metro_South_Group_9.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_LV_METRO_SOUTH_GROUP10, '/data_overlays/energex/Network_LV/Network_LV_Metro_South_Group_10.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_LV_METRO_SOUTH_GROUP11, '/data_overlays/energex/Network_LV/Network_LV_Metro_South_Group_11.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_LV_METRO_SOUTH_GROUP12, '/data_overlays/energex/Network_LV/Network_LV_Metro_South_Group_12.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_LV_METRO_SOUTH_GROUP13, '/data_overlays/energex/Network_LV/Network_LV_Metro_South_Group_13.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_LV_NORTH_COAST_GROUP1, '/data_overlays/energex/Network_LV/Network_LV_North_Coast_Group_1.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_LV_NORTH_COAST_GROUP2, '/data_overlays/energex/Network_LV/Network_LV_North_Coast_Group_2.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_LV_NORTH_COAST_GROUP3, '/data_overlays/energex/Network_LV/Network_LV_North_Coast_Group_3.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_LV_NORTH_COAST_GROUP4, '/data_overlays/energex/Network_LV/Network_LV_North_Coast_Group_4.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_LV_NORTH_COAST_GROUP5, '/data_overlays/energex/Network_LV/Network_LV_North_Coast_Group_5.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_LV_NORTH_COAST_GROUP6, '/data_overlays/energex/Network_LV/Network_LV_North_Coast_Group_6.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_LV_NORTH_COAST_GROUP7, '/data_overlays/energex/Network_LV/Network_LV_North_Coast_Group_7.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_LV_NORTH_COAST_GROUP8, '/data_overlays/energex/Network_LV/Network_LV_North_Coast_Group_8.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_LV_NORTH_COAST_GROUP9, '/data_overlays/energex/Network_LV/Network_LV_North_Coast_Group_9.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_LV_NORTH_COAST_GROUP10, '/data_overlays/energex/Network_LV/Network_LV_North_Coast_Group_10.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_LV_NORTH_COAST_GROUP11, '/data_overlays/energex/Network_LV/Network_LV_North_Coast_Group_11.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_LV_NORTH_COAST_GROUP12, '/data_overlays/energex/Network_LV/Network_LV_North_Coast_Group_12.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_LV_SOUTH_COAST_GROUP1, '/data_overlays/energex/Network_LV/Network_LV_South_Coast_Group_1.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_LV_SOUTH_COAST_GROUP2, '/data_overlays/energex/Network_LV/Network_LV_South_Coast_Group_2.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_LV_SOUTH_COAST_GROUP3, '/data_overlays/energex/Network_LV/Network_LV_South_Coast_Group_3.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_LV_SOUTH_COAST_GROUP4, '/data_overlays/energex/Network_LV/Network_LV_South_Coast_Group_4.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_LV_SOUTH_COAST_GROUP5, '/data_overlays/energex/Network_LV/Network_LV_South_Coast_Group_5.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_LV_SOUTH_COAST_GROUP6, '/data_overlays/energex/Network_LV/Network_LV_South_Coast_Group_6.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_LV_SOUTH_COAST_GROUP7, '/data_overlays/energex/Network_LV/Network_LV_South_Coast_Group_7.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_LV_SOUTH_COAST_GROUP8, '/data_overlays/energex/Network_LV/Network_LV_South_Coast_Group_8.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_LV_SOUTH_COAST_GROUP9, '/data_overlays/energex/Network_LV/Network_LV_South_Coast_Group_9.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_LV_WESTERN_GROUP1, '/data_overlays/energex/Network_LV/Network_LV_Western_Group_1.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_LV_WESTERN_GROUP2, '/data_overlays/energex/Network_LV/Network_LV_Western_Group_2.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_LV_WESTERN_GROUP3, '/data_overlays/energex/Network_LV/Network_LV_Western_Group_3.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_LV_WESTERN_GROUP4, '/data_overlays/energex/Network_LV/Network_LV_Western_Group_4.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_LV_WESTERN_GROUP5, '/data_overlays/energex/Network_LV/Network_LV_Western_Group_5.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_LV_WESTERN_GROUP6, '/data_overlays/energex/Network_LV/Network_LV_Western_Group_6.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_LV_WESTERN_GROUP7, '/data_overlays/energex/Network_LV/Network_LV_Western_Group_7.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_LV_WESTERN_GROUP8, '/data_overlays/energex/Network_LV/Network_LV_Western_Group_8.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_LV_WESTERN_GROUP9, '/data_overlays/energex/Network_LV/Network_LV_Western_Group_9.v1.geojson'],
    [InterfaceHubGeoFeatures.ENERGEX_LV_WESTERN_GROUP10, '/data_overlays/energex/Network_LV/Network_LV_Western_Group_10.v1.geojson'],
]);

export class DBTools {
	static date_invalid = new Date(0);

    // static detection_type_names = {
    //     'EventAlertPersonalTemperature': 'Fever Alert',
    //     'EventAlertFire': 'Fire Alert',
    //     'EventAlertFall': 'Fall Alert',
    //     'EventAlertAmbientClimate': 'Climate Alert',
    //     'EventPersonalRequest': 'Personal Request',
    //     'EventPrototype': 'Debug'
    // };

    // static detection_type_icons = {
    //     'EventAlertPersonalTemperature': 'fa-thermometer',
    //     'EventAlertFire': 'fa-fire',
    //     'EventAlertFall': 'fa-caret-square-down',
    //     'EventAlertAmbientClimate': 'fa-cloud-sun',
    //     'EventPersonalRequest': 'fa-question-circle',
    //     'EventPrototype': 'fa-cogs'
    // };

    // static detection_type_themes = {
    //     'EventAlertPersonalTemperature': 'severity-warn',
    //     'EventAlertFire': 'severity-crit',
    //     'EventAlertFall': 'severity-crit',
    //     'EventAlertAmbientClimate': 'severity-warn',
    //     'EventPersonalRequest': 'severity-info',
    //     'EventPrototype': 'severity-info'
    // };

    // static detection_personal_request_reasons = {
    //     0: 'Help',
    //     1: 'Water',
    //     2: 'Bathroom',
    //     3: 'Food',
    //     4: 'Company',
    //     5: 'Entertainment',
    //     6: 'Movement',
    //     4294967295: 'Debug'
    // };

    // static detection_alert_ambient_climate_reasons = {
    //     0: 'Unknown',
    //     1: 'Low Temp.',
    //     2: 'High Temp.',
    //     4: 'Low Humid.',
    //     8: 'High Humid.'
    // };

    // static detection_alert_fall_states = {
    //     0: 'Unknown',
    //     1: 'Laying',
    //     2: 'Sitting'
    // };

    // static detection_alert_fire_sizes = {
    //     0: 'Unknown',
    //     1: 'Small',
    //     2: 'Medium',
    //     3: 'Large'
    // };

    // static device_features = {
    //     0: 'Disabled',
    //     1: 'WiFi',
    //     2: 'Bluetooth',
    //     4: 'Lighting',
    //     8: 'Power-save',
    //     16: 'RGB Camera',
    //     32: 'Thermal Camera',
    //     64: 'Ambient Climate'
    // };

    //Generic function to decode bitwise tables from a given bit set
    //e.g. enabled_features = decode_bitwise_messages(features, DBTools.device_features);
    static decode_bitwise_messages(bits:number, table:any) {
        let msgs = [];
        if(bits > 0) {
            for(const [key, value] of Object.entries(table)) {
                if(bits & Number.parseInt(key))
                    msgs.push(value);
            }

            //We had bits set, but no results
            if(msgs.length == 0)
                msgs.push('Undefined');
        } else {
            //This is the '0' case, no bits set
            msgs.push(table[0]);
        }

        return msgs;
    }


    /*
    *  ============================================================================================
    *  Helper Functions
    *  ============================================================================================
    */
    static delay(ms:number) {
        return new Promise(res => setTimeout(res, ms));
    }

    static generate_uid() {
        let firstPart = (Math.random() * 46656) | 0;
        let secondPart = (Math.random() * 46656) | 0;
        let first = ("000" + firstPart.toString(36)).slice(-3);
        let second = ("000" + secondPart.toString(36)).slice(-3);
        return first + second;
    }

    static generate_hex_key() {
        return [DBTools.generate_uid(),DBTools.generate_uid(),DBTools.generate_uid()].join('-').toUpperCase();
    }

	static get_url_hash_item(req_key:string) {
		let value = null;
		if(window.location.hash) {
			let hash = window.location.hash.slice(1).split('&');	//Drop '#' and split options
			for(const key of hash) {
				if(key.includes(req_key))
					value = key.split('=').pop();
			}
		}

		return value;
	}

    static is_local() {
        const host = window.location.hostname;
        return host == 'localhost' || host == '127.0.0.1' || host == '0.0.0.0';
    }

    static create_interface(interface_name:string) {
        let i = null;
        switch(interface_name) {
            case 'mock': {
                i = new DatabaseMock();
                break;
            }
            case 'firebase': {
                i = new DatabaseFirebase();
                break;
            }
        }

        return i;
    }

    static get_time_span_severity(timestamp:Date, time_span:number, now:Date = new Date()) {
        let severity = SEVERITY.NONE;
		const dead_date = CommonLib.add_days(timestamp, time_span);
        let ms_left = dead_date.getTime() - now.getTime();
        let cd = 24 * 60 * 60 * 1000;
        let days_left = Math.floor(ms_left / cd);

		if(days_left <= 0) {
			severity = SEVERITY.CRIT;
		} else if(days_left <= 5) {
			severity = SEVERITY.WARN;
		} else if(days_left <= 30) {
			severity = SEVERITY.INFO;
		}

        return <LifetimeWarningData>{
            days: days_left,
            severity: severity,
            stamp: dead_date,
        };
    }

    /*
    *  ============================================================================================
    *  Creation Functions
    *  ============================================================================================
    */
    static create_hub(admin_id:string, date_allocated=new Date()) {
        return <InterfaceHub> {
            admin: admin_id,
            users: [],
            archived: false,
            activation_secret: DBTools.generate_hex_key(),
            date_allocated: date_allocated,
            name: 'Hub #' + this.generate_uid().toString().toUpperCase(),
            owner: 'V-TOL Aerospace',
            location: {
                'latitude': 0.0,
                'longitude': 0.0
            },
        }
    }

    static create_user(admin_hub_ids:string[]) {
        return <InterfaceUserProfile> {
            datetime_created: new Date(),
            name_first: 'Kye',
            name_last: 'Morton',
            phone: '(07) 3275 2811',
            mobile: '0432933828',
            email: 'kye.morton@v-tol.com',
            username: 'kye.morton@v-tol.com',
            hubs: admin_hub_ids,
        }
    }


    static create_device(type:InterfaceDeviceType) {
        let device:InterfaceDeviceBase|InterfaceDeviceStatic|InterfaceDeviceSensor = null;

        switch(type) {
            case InterfaceDeviceType.ROTAFLAG:{
                device = <InterfaceDeviceStatic>{
                    type: InterfaceDeviceType.ROTAFLAG,
                    archived: false,
                    timestamp: new Date(),
                    uuid: "",
                    location: new CosecGeoPoint(),
                    name: "",
                    date_install: new Date(),
                    date_review: new Date(),
                    review: 0,
                    warranty: 0,
                    renewal: 0,
                    lifespan: 0,
                };

                break;
            }
            case InterfaceDeviceType.ROTAMARKER_RW:{
                device = <InterfaceDeviceStatic>{
                    type: InterfaceDeviceType.ROTAMARKER_RW,
                    archived: false,
                    timestamp: new Date(),
                    uuid: "",
                    location: new CosecGeoPoint(),
                    name: "",
                    date_install: new Date(),
                    date_review: new Date(),
                    review: 0,
                    warranty: 0,
                    renewal: 0,
                    lifespan: 0,
                };

                break;
            }
            case InterfaceDeviceType.ROTAMARKER_BW:{
                device = <InterfaceDeviceStatic>{
                    type: InterfaceDeviceType.ROTAMARKER_BW,
                    archived: false,
                    timestamp: new Date(),
                    uuid: "",
                    location: new CosecGeoPoint(),
                    name: "",
                    date_install: new Date(),
                    date_review: new Date(),
                    review: 0,
                    warranty: 0,
                    renewal: 0,
                    lifespan: 0,
                };

                break;
            }
            case InterfaceDeviceType.ROTASENSOR:{
                device = <InterfaceDeviceSensor>{
                    type: InterfaceDeviceType.ROTASENSOR,
                    archived: false,
                    identify: false,
                    release: false,
                    date_boot: new Date(),
                    date_contacted: new Date(),
                    timestamp: new Date(),
                    uuid: "",
                    location: new CosecGeoPoint(),
                    name: "",
                    msg_type: 0,
                    features: {},
                };

                break;
            }
            case InterfaceDeviceType.GENERIC:{
                device = <InterfaceDeviceBase>{
                    type: InterfaceDeviceType.GENERIC,
                    archived: false,
                    timestamp: new Date(),
                    uuid: "",
                    location: new CosecGeoPoint(),
                    name: "",
                };

                break;
            }
        }

        return device;
    }

    static create_threshold() {
        return <InterfaceReading>{
            ambience: {
                temperature: null,
                pressure:null,
                humidity:null,
            },
            power: {
                voltage_batt: null,
                voltage_solar:null,
            },
            range: {
                angle: null,
                distance_adj:null,
                distance_raw:null,
            }
        }
    }

    static get_reading_warnings(reading:InterfaceReading, thresholds:InterfaceReadingThresholds=null) {
        let max_warning = SEVERITY.NONE;

		let warnings_high:InterfaceReadingWarnings = {
            ambience: {
                temperature: SEVERITY.NONE,
                pressure: SEVERITY.NONE,
                humidity: SEVERITY.NONE,
            },
            power: {
                voltage_batt: SEVERITY.NONE,
                voltage_solar: SEVERITY.NONE,
            },
            range: {
                angle: SEVERITY.NONE,
                distance_adj: SEVERITY.NONE,
                distance_raw: SEVERITY.NONE,
            }
        };

		let warnings_low:InterfaceReadingWarnings = {
            ambience: {
                temperature: SEVERITY.NONE,
                pressure: SEVERITY.NONE,
                humidity: SEVERITY.NONE,
            },
            power: {
                voltage_batt: SEVERITY.NONE,
                voltage_solar: SEVERITY.NONE,
            },
            range: {
                angle: SEVERITY.NONE,
                distance_adj: SEVERITY.NONE,
                distance_raw: SEVERITY.NONE,
            }
        };

		if(thresholds && thresholds.min) {
			if(reading.ambience && thresholds.min.ambience) {
				if((thresholds.min.ambience.temperature != null) && (reading.ambience.temperature < thresholds.min.ambience.temperature)) {
                    warnings_low.ambience.temperature = SEVERITY.INFO;
                    if(warnings_low.ambience.temperature > max_warning)
                        max_warning = warnings_low.ambience.temperature;
                }

				if((thresholds.min.ambience.pressure != null) && (reading.ambience.pressure < thresholds.min.ambience.pressure)) {
                    warnings_low.ambience.pressure = SEVERITY.INFO;
                    if(warnings_low.ambience.pressure > max_warning)
                    max_warning = warnings_low.ambience.pressure;
                }

				if((thresholds.min.ambience.humidity != null) && (reading.ambience.humidity < thresholds.min.ambience.humidity)) {
                    warnings_low.ambience.humidity = SEVERITY.INFO;
                    if(warnings_low.ambience.humidity > max_warning)
                    max_warning = warnings_low.ambience.humidity;
                }
			}

			if(reading.power && thresholds.min.power) {
				if((thresholds.min.power.voltage_batt != null) && (reading.power.voltage_batt < thresholds.min.power.voltage_batt)) {
                    warnings_low.power.voltage_batt = SEVERITY.WARN;
                    if(warnings_low.power.voltage_batt > max_warning)
                        max_warning = warnings_low.power.voltage_batt;
                }

				if((thresholds.min.power.voltage_solar != null) && (reading.power.voltage_solar < thresholds.min.power.voltage_solar)) {
                    warnings_low.power.voltage_solar = SEVERITY.INFO;
                    if(warnings_low.power.voltage_solar > max_warning)
                        max_warning = warnings_low.power.voltage_solar;
                }
			}

			if(reading.range && thresholds.min.range) {
				if((thresholds.min.range.angle != null) && (reading.range.angle < thresholds.min.range.angle)) {
                    warnings_low.range.angle = SEVERITY.WARN;
                    if(warnings_low.range.angle > max_warning)
                        max_warning = warnings_low.range.angle;
                }

				if((thresholds.min.range.distance_raw != null) && (reading.range.distance_raw < thresholds.min.range.distance_raw)) {
                    warnings_low.range.distance_raw = SEVERITY.CRIT;
                    if(warnings_low.range.distance_raw > max_warning)
                        max_warning = warnings_low.range.distance_raw;
                }

				if((thresholds.min.range.distance_adj != null) && (reading.range.distance_adj < thresholds.min.range.distance_adj)) {
                    warnings_low.range.distance_adj = SEVERITY.CRIT;
                    if(warnings_low.range.distance_adj > max_warning)
                        max_warning = warnings_low.range.distance_adj;
                }
			}
		}

		if(thresholds && thresholds.max) {
			if(reading.ambience && thresholds.max.ambience) {
				if((thresholds.max.ambience.temperature != null) && (reading.ambience.temperature > thresholds.max.ambience.temperature)) {
                    warnings_high.ambience.temperature = SEVERITY.INFO;
                    if(warnings_high.ambience.temperature > max_warning)
                    max_warning = warnings_high.ambience.temperature;
                }

				if((thresholds.max.ambience.pressure != null) && (reading.ambience.pressure > thresholds.max.ambience.pressure)) {
                    warnings_high.ambience.pressure = SEVERITY.INFO;
                    if(warnings_high.ambience.pressure > max_warning)
                        max_warning = warnings_high.ambience.pressure;
                }

				if((thresholds.max.ambience.humidity != null) && (reading.ambience.humidity > thresholds.max.ambience.humidity)) {
                    warnings_high.ambience.humidity = SEVERITY.INFO;
                    if(warnings_high.ambience.humidity > max_warning)
                        max_warning = warnings_high.ambience.humidity;
                }
			}

			if(reading.power && thresholds.max.power) {
				if((thresholds.max.power.voltage_batt != null) && (reading.power.voltage_batt > thresholds.max.power.voltage_batt)) {
                    warnings_high.power.voltage_batt = SEVERITY.WARN;
                    if(warnings_high.power.voltage_batt > max_warning)
                        max_warning = warnings_high.power.voltage_batt;
                }

				if((thresholds.max.power.voltage_solar != null) && (reading.power.voltage_solar > thresholds.max.power.voltage_solar)) {
                    warnings_high.power.voltage_solar = SEVERITY.INFO;
                    if(warnings_high.power.voltage_solar > max_warning)
                        max_warning = warnings_high.power.voltage_solar;
                }
			}

			if(reading.range && thresholds.max.range) {
				if((thresholds.max.range.angle != null) && (reading.range.angle > thresholds.max.range.angle)) {
                    warnings_high.range.angle = SEVERITY.WARN;
                    if(warnings_high.range.angle > max_warning)
                        max_warning = warnings_high.range.angle;
                }

				if((thresholds.max.range.distance_raw != null) && (reading.range.distance_raw > thresholds.max.range.distance_raw)) {
                    warnings_high.range.distance_raw = SEVERITY.CRIT;
                    if(warnings_high.range.distance_raw > max_warning)
                        max_warning = warnings_high.range.distance_raw;
                }

				if((thresholds.max.range.distance_adj != null) && (reading.range.distance_adj > thresholds.max.range.distance_adj)) {
                    warnings_high.range.distance_adj = SEVERITY.CRIT;
                    if(warnings_high.range.distance_adj > max_warning)
                        max_warning = warnings_high.range.distance_adj;
                }
			}
		}

		return <InterfaceReadingWarningsSummary>{high: warnings_high, low: warnings_low, max: max_warning};
    }

    // static create_event(device_id) {
    //     let event = {};
    //     event['device_id'] = device_id;

    //     event['type'] = 'EventPrototype';

    //     event['detection'] = {
    //         'data': 'Rand: ' + Math.random()
    //     };

    //     event['images'] = {
    //         // 'rgb': this.generate_uid(),
    //         // 'thermal': this.generate_uid()
    //     };

    //     event['raised'] = new Date();
    //     //Generate as 'cleared' if it's not a positive
    //     if(event['detection']['positive']) {
    //         event['cleared'] = new Date(0);
    //     } else {
    //         event['cleared'] = event['raised'];
    //     }

    //     return event;
    // }

    // static create_premises(id, admin_id, user_ids, devices) {
    //     let premises = {};

    //     premises['address'] = '123 Fake Street, Brisbane, 4000';
    //     premises['name'] = 'Premises #' + id.toString();

    //     premises['devices'] = devices;
    //     premises['archived'] = false;

    //     premises['admin'] = admin_id;
    //     premises['users'] = user_ids;

    //     premises['events'] = new Map();
    //     premises['event_images'] = new Map();

    //     return premises;
    // }

    // static create_events(device_id, count) {
    //     let data = {};
    //     data['events'] = new Map();

    //     for(const i of Array(count).keys()) {
    //         data['events'].set(this.generate_uid(), this.create_event(device_id));
    //     }

    //     return data;
    // }

    // static create_event_images(events) {
    //     let data = {};
    //     data['event_images'] = new Map();

    //     events.forEach(function(value, key){
    //         let img_rgb = {};
    //         img_rgb['data_b64'] = '';
    //         img_rgb['event_id'] = key;

    //         let img_thermal = {};
    //         img_thermal['data_b64'] = '';
    //         img_thermal['event_id'] = key;

    //         data['event_images'].set(value['image_rgb'], img_rgb);
    //         data['event_images'].set(value['image_thermal'], img_thermal);
    //     });

    //     return data;
    // }
}
