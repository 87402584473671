import { CosecCore, CosecCoreHashKey } from "./core";
import { get_database_connector } from "./db_firebase";
import { DBTools } from "./db_tools";
import { InterfaceUserProfile } from "./interface_database";

let _key_form_start_demo = 'loader-start-demo-form';
let _key_form_start_database = 'loader-login-form';

let database_connector_unsubscribe:()=>void = null;
function shutdown_database_connector() {
    if(database_connector_unsubscribe)
        database_connector_unsubscribe();
    database_connector_unsubscribe = null;

    // if(database_connector)
    //     database_connector.shutdown();
    // database_connector = null;
}

function create_mock_login_form(id:string) {
    let f = document.createElement('form');
    f.id = id;
    f.username = document.createElement('input');
    f.username.value = "";
    f.password = document.createElement('input');
    f.password.value = "";
    f['button-submit'] = document.createElement('button');

    return f;
}

export function show_login_screen() {
    shutdown_database_connector();
    database_connector_unsubscribe = get_database_connector().on_auth_changed((user_id:string, user_data:InterfaceUserProfile) => {
        if(user_id != null) {
            handleLoaderTasks(create_mock_login_form(_key_form_start_database));
        } else {
            console.log("Skipping automatic login: sign out | invalid");

            document.getElementById('loader-login-form').style.display = 'grid';
            document.getElementById('loader-login-dots').style.display = 'none';
        }
    })

    //Handle a hot-link to the demo-mode and auto-login modes
    if(DBTools.get_url_hash_item(CosecCoreHashKey.DEMO)) {
        handleLoaderTasks(create_mock_login_form(_key_form_start_demo));
    } else {
        document.getElementById('loader-dots').style.display = 'none';
        document.getElementById('loader-user-area').style.display = 'flex';
    }
}


/**
 * Creates all of the backend classes ahead of application start.
 * Requires context on use of the demo mode
 * @param  {} use_demo_mode=false Set to true to use the demo mode
 */
 function create_app_interfaces(use_demo_mode=false) {
    //TODO: Remove
    //@ts-ignore
    window.database_interface = DBTools.create_interface(use_demo_mode ? 'mock' : 'firebase');
    window.cosec_core = new CosecCore(window.database_interface);
}

/**
 * Handles the pre-app tasks of login, registration, and demo mode.
 * XXX: This will indirectly perform the application loading through:
 *      database_interface.user_login() -> [success] -> load_app_data()
 * @param  {} context The context of the calling menu/form
 */
export function handleLoaderTasks(context:HTMLFormElement) {
    //Hide parent box
    document.getElementById('loader-user-area').style.display = 'none';
    document.getElementById('loader-dots').style.display = 'flex';

    shutdown_database_connector();

    if(context.id == _key_form_start_demo) {
        do_email_callback(context['username'].value);
        create_app_interfaces(true);
        window.database_interface.user_login('mock_user', 'mock_password', window.login_finished.bind(this));
        //TODO!
    // } else if(context.id == 'loader-register-form') {
    //     //Hide the error box (to prevent redraw)
    //     let error_txt = document.getElementById('loader-register-error')
    //     error_txt.style.display = 'none';
    //     //Do register
    //     let username = document.getElementById('cosec-register-username').value;
    //     let password = document.getElementById('cosec-register-password').value;

    //     if(username && password) {
    //         if(validateEmail(username)) {
    //             database_interface.user_register(username, password); 1y
    //         } else {
    //             error = error_txt;
    //             error_message = 'Invalid email';
    //         }
    //     } else {
    //         error = error_txt;
    //         error_message = 'All fields are required';
    //     }
    } else if(context.id == _key_form_start_database) {
        //Hide the error box (to prevent redraw)
        let error_txt = document.getElementById('loader-login-error');
        error_txt.innerHTML = '';
        error_txt.appendChild(document.createTextNode("_"));
        error_txt.style.visibility = "hidden";
        const but = context['button-submit'];
        // error_txt.style.display = 'none';

        //Do login
        const username = context['username'].value;
        const password = context['password'].value;

        create_app_interfaces(false);
        window.database_interface.user_login(username, password, window.login_finished.bind(this));
        but.disabled = true;
    } else {
        console.error(`Unknown context: ${context}`)
    }
}

/**
 * Regex check to ensure some kind of regular email address
 * @param  {} email Email address to be checked
 */
 function validateEmail(email:string) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

function _do_email_callback(email:string, country="Unknown", name="Anonymous", company="Unknown", number="") {
    //Easy-to-change Parameters
    const boundary = DBTools.generate_hex_key();
    const prefix_display = "Content-Disposition: form-data; name=";
    const form_number = "87";
    const form_url = `https://digitaltwin.energy/wp-json/contact-form-7/v1/contact-forms/${form_number}/feedback`;

    //Prepare form data
    const data = new Map();
    data.set("_wpcf7", form_number);
    data.set("_wpcf7_version", "5.5.6");
    data.set("_wpcf7_locale", "en_US");
    data.set("_wpcf7_unit_tag", "wpcf7-f87-o1");
    data.set("_wpcf7_container_post", "0");
    data.set("_wpcf7_posted_data_hash", "");
    data.set("your-name", name);
    data.set("your-company", company);
    data.set("Country", country);
    data.set("your-email", email);
    if(number)
        data.set("number-883", number); //XXX: Optional
    data.set("your-message", "The contact mentioned would like a callback. This email was automatically generated.");

    //Push form data into the correct post format
    let body = "";
    for(const [key, value] of data)
        body += "--" + boundary + "\r\n"
        + prefix_display + `"${key}"\r\n`
        + "\r\n"
        + `${value}\r\n`;

    body += "--" + boundary + "--\r\n"

    //Make the post request
    let xhr = new XMLHttpRequest();
    xhr.open("POST", form_url);
    xhr.setRequestHeader('Content-Type', 'multipart/form-data; boundary=' + boundary);
    xhr.onreadystatechange = function() {
        //Provide some console feedback for debugging!
        if (xhr.readyState == XMLHttpRequest.DONE) {
            if(xhr.status == 200) {
                const res = JSON.parse(xhr.response);
                if (res.status == "mail_sent") {
                    console.log(`Email callback: ${res.message}`);
                } else {
                    console.warn(`Email callback: ${res.message}`);
                    // console.warn(xhr.response.invalid_fields);
                }
            } else {
                console.error("Email callback: Unable to send email callback!");
                // console.error(xhr);
            }
        }
    }
    xhr.send(body);
}

async function do_email_callback(email:string) {
    if(validateEmail(email)) {
        try{
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    _do_email_callback(email, [position.coords.latitude, position.coords.longitude].toString());
                },
                /* eslint-disable no-unused-vars */
                (err) => {
                    _do_email_callback(email);
                }
                /* eslint-enable no-unused-vars */
            );
        }
        catch(error) {
            console.log(error);
        }
    } else {
        console.log("Starting demo without email callback");
    }
}
